import React, { useState, useEffect } from "react";
import AddQuiz from "./AddQuiz";
import QuizList from "./QuizList";
import Courses from "../courses/Courses";
import Assignments from "../Assignments";

export default function Quiz() {
  const [activeLink, setActiveLink] = useState(null);
  const [activeTab, setActiveTab] = useState("Courses");
  let content = null;

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  switch (activeLink) {
    case "Quizzes":
      content = <QuizList />;
      break;
    case "AddNewQuiz":
      content = <AddQuiz />;
      break;
    case "Courses":
      content = <Courses />;
      break;
    case "Assignments":
      content = <Assignments />;
      break;

    default:
      content = <Courses />;
  }

  useEffect(() => {
    const jsDemoImagesTransform = document.createElement("script");
    jsDemoImagesTransform.type = "text/javascript";
    jsDemoImagesTransform.src =
      "https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image";
    document.head.appendChild(jsDemoImagesTransform);
    require("@wiris/mathtype-tinymce5");
  }, []);

  return (
    <div className="md:container">
      <div className="bg-backgroundMain text-white">
        <div className="flex items-center justify-between p-4 bg-sidebar">
          <div className="flex items-center">
            <div className="p-2 rounded-full">
              <img
                src="../../../../logo.PNG"
                alt="menu icon"
                className="w-10 h-10 border-2 border-gray-300 p-1 shadow-sm"
              />
            </div>
            <nav className="ml-4">
              <ul className="flex space-x-4">
                <li>
                  <button
                    className={`flex items-center font-semibold leading-8 transition-colors duration-300 ${
                      activeTab === "Courses"
                        ? "text-[#93ddf3]"
                        : "text-white hover:text-gray-300"
                    }`}
                    onClick={() => {
                      handleLinkClick("Courses");
                      setActiveTab("Courses");
                    }}
                  >
                    <i className="fas fa-book mr-2 fa-xs"></i>
                    Courses
                  </button>
                </li>
                <li>
                  <button
                    className={`flex items-center font-semibold leading-8 transition-colors duration-300 ${
                      activeTab === "Assignments"
                        ? "text-[#93ddf3]"
                        : "text-white hover:text-gray-300"
                    }`}
                    onClick={() => {
                      handleLinkClick("Assignments");
                      setActiveTab("Assignments");
                    }}
                  >
                    <i className="fas fa-tasks mr-2 fa-xs"></i>
                    Assignments
                  </button>
                </li>
                <li>
                  <button
                    className={`flex items-center font-semibold leading-8 transition-colors duration-300 ${
                      activeTab === "Quizzes"
                        ? "text-[#93ddf3]"
                        : "text-white hover:text-gray-300"
                    }`}
                    onClick={() => {
                      handleLinkClick("Quizzes");
                      setActiveTab("Quizzes");
                    }}
                  >
                    <i className="fas fa-clipboard mr-2 fa-xs"></i>
                    Quizzes
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="p-3 bg-cardsButtons mb-3">
          <div className="text-2xl font-bold text-textcolor mt-2 md:text-xl sm:text-lg">
            <i className="fas fa-graduation-cap mr-2"></i>
            Edumate Learning
          </div>
          <div className="items-center mt-2">
            <div className="w-full">
              <p className="text-textcolor text-xl md:text-lg sm:text-base">
                Welcome to Edumate Learning Hub! Easily create quizzes and
                courses. Enhance teaching with intuitive tools and make learning
                interactive with Edumate!
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="content">{content}</div>
    </div>
  );
}
