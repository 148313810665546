import React from "react";
import Hero from "../../components/common/hero/Hero";

export default function Pricing() {
  return (
    <>
      <Hero
        heading="Price Table"
        paragraph="Our payment plans are designed to accommodate the diverse needs of our clients, taking into account factors such as assignment complexity, subject matter, and any additional instructions or requirements. For tutoring services, we offer both online and in-person options, with a discount of 10% applied to online sessions."
        buttonText="Enquire"
        backgroundImage=""
      />
      <div className="bg-gray-200 ">
        <div className="container py-10">
          <div className=" py-16">
            <div className="text-center">
              <p className="text-textcolor text-2xl font-bold mb-2">
                Coming Soon
              </p>
              <p className="text-textcolor text-sm">
                Stay tuned for our pricelist!
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
