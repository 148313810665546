// TransactionButton.js
import React from "react";
import { initializeTransaction } from "../../../api/payment_processing";

const TransactionButton = ({ email, price, plan, children }) => {
  const handleClick = async () => {
    try {
      const data = await initializeTransaction(email, price, plan);

      if (data && data.data && data.data.authorization_url) {
        const authorizationUrl = data.data.authorization_url;
        window.open(authorizationUrl, "_blank");
        return null;
      } else {
        return new Error(
          "Transaction initialization failed or returned incomplete data."
        );
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <button
      onClick={handleClick}
      className="inline-flex items-center justify-center w-full h-12 px-6 font-semibold tracking-wide text-teal-900 transition duration-200 rounded shadow-md bg-white hover:bg-teal-accent-700 focus:shadow-outline focus:outline-none"
    >
      {children}
    </button>
  );
};

export default TransactionButton;
