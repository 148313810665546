import React, { useState } from "react";
import LearningHome from "./partials/LearningHome";
import CourseView from "./courses/CourseView";
import QuizView from "./Quiz/QuizView";

export default function Learning() {
  const [activeLink, setActiveLink] = useState("learning");
  const [selectedQuiz, setSelectedQuiz] = useState(null);

  const handleQuizClick = (quiz) => {
    setSelectedQuiz(quiz);
    setActiveLink("quizview");
  };

  const handleBackToHome = () => {
    setSelectedQuiz(null);
    setActiveLink("learning");
  };

  let content;
  switch (activeLink) {
    case "learning":
      content = <LearningHome onQuizClick={handleQuizClick} />;
      break;
    case "quizview":
      content = <QuizView quiz={selectedQuiz} onBack={handleBackToHome} />;
      break;
    case "courseview":
      content = <CourseView />;
      break;
    default:
      content = <LearningHome onQuizClick={handleQuizClick} />;
  }

  return <div className="md:container p-1">{content}</div>;
}
