import React from "react";

export default function AdminHeader({ headerText, descriptionText }) {
  return (
    <div className="bg-backgroundMain text-white">
      <div className="flex items-center justify-between p-4 bg-sidebar">
        <div className="flex items-center">
          <div className="p-2 rounded-full">
            <img
              src="../../../../logo.PNG"
              alt="menu icon"
              className="w-10 h-10 border-2 border-gray-300 p-1 shadow-sm"
            />
          </div>
          <h2 className="text-cardsButtons text-2xl font-bold">{headerText}</h2>
        </div>
      </div>
      <div className="p-3 bg-cardsButtons mb-3">
        <div className="text-2xl font-bold text-textcolor mt-2 md:text-xl sm:text-lg">
          <i className="fas fa-graduation-cap mr-2"></i>
          Edumate Learning
        </div>
        <div className="items-center mt-2">
          <div className="w-full">
            <p className="text-textcolor text-xl md:text-lg sm:text-base">
              {descriptionText}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
