import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Nav() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    if (window.innerWidth <= 768) {
      setIsMenuOpen(false);
    }
  };

  return (
    <nav className="border-gray-900 bg-gray-900 text-gray-200 fixed top-0 w-full z-10">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-2">
        <a href="#" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src="../../../../logo.PNG" className="h-8" alt="Flowbite Logo" />
          <span className="self-center text-xl font-extrabold leading-8 whitespace-nowrap ">
            EDUMATE
          </span>
        </a>
        <button
          onClick={toggleMenu}
          type="button"
          className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 hover:text-green-400"
          aria-controls="navbar-solid-bg"
          aria-expanded={isMenuOpen}
        >
          <span className="sr-only">Open main menu</span>
          <svg
            className="w-5 h-5"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 17 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M1 1h15M1 7h15M1 13h15"
            />
          </svg>
        </button>
        <div
          className={`${
            isMenuOpen ? "block" : "hidden"
          } w-full md:block md:w-auto`}
          id="navbar-solid-bg"
        >
          <ul className="flex flex-col font-medium mt-2 mb-1 rounded-lg bg-[#709fa8] md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-transparent">
            <li>
              <Link
                to="/"
                onClick={closeMenu}
                className="block  px-3 md:p-0 text-gray-200 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#719fa9] font-semibold leading-8"
                aria-current="page"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/pricing"
                onClick={closeMenu}
                className="block  px-3 md:p-0 text-gray-200 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#719fa9] font-semibold leading-8"
              >
                Pricing
              </Link>
            </li>
            <li>
              <Link
                to="/events"
                onClick={closeMenu}
                className="block  px-3 md:p-0 text-gray-200 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#719fa9] font-semibold leading-8"
              >
                Events
              </Link>
            </li>

            <li>
              <Link
                to="/signup"
                onClick={closeMenu}
                className="block  px-3 md:p-0 text-gray-200 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#719fa9] font-semibold leading-8"
              >
                Signup
              </Link>
            </li>
            <li>
              <Link
                to="/login"
                onClick={closeMenu}
                className=" bg-backgroundMain block  px-3 md:p-0 text-[#719fa9] rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#719fa9] font-semibold leading-8"
              >
                <i className="fas fa-sign-in-alt mr-2"></i>
                Login
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
