import React from "react";

export default function Hero({
  heading,
  paragraph,
  buttonText,
  backgroundImage,
}) {
  return (
    <div>
      <div className="slider-area features pos-relative">
        <div className="slider-active">
          <div
            className="single-slider slider-height d-flex align-items-center justify-content-center"
            style={{
              backgroundImage: `url("${backgroundImage}")`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              zIndex: -1,
            }}
          >
            <div className="container ml-3 mr-3 mt-5 ">
              <div className="row">
                <div className="col-xl-9 col-md-12">
                  <div className="slider-content slider-content-2">
                    <h1 className="text-[#719fa9] f-700 font-extrabold">
                      {heading}
                    </h1>
                    <p
                      data-animation="fadeInUp"
                      className="text-xl font-normal text-gray-500 lg:text-xl text-shadow"
                      data-delay=".4s"
                    >
                      {paragraph}
                    </p>
                    <button
                      className="theme-btn"
                      data-animation="fadeInUp"
                      data-delay=".6s"
                    >
                      <span className="btn-text signupBtn">{buttonText}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
