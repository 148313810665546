import React, { useState, useEffect, useMemo } from "react";
import { Spinner, Modal, Button, Form } from "react-bootstrap";
import { useUser } from "../../context/AuthContext";
import MyPagination from "../../components/common/others/MyPagination";
import AdminHeader from "../../components/common/header/AdminHeader";
import { toast } from "react-toastify";
import UserModal from "./partials/UserModal";

export default function Users() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(null);
  const [loadingU, setLoadingU] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    role: "Student",
    agreedToTerms: false,
  });

  const { signup, getAllStudents, deleteUser, updateUser } = useUser();

  const validateForm = () => {
    let formErrors = {};
    if (!formData.name.trim()) {
      formErrors.name = "First name is required";
    }
    if (!formData.email.trim()) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Invalid email address";
    }
    if (!formData.phoneNumber.trim()) {
      formErrors.phoneNumber = "Phone number is required";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    try {
      setLoadingU(true);
      const userData = {
        ...formData,
        password: formData.phoneNumber,
      };
      if (selectedUser) {
        await updateUser(selectedUser.uid, userData);
      } else {
        await signup(userData);
      }
      setLoadingU(false);
      setFormData({
        name: "",
        email: "",
        phoneNumber: "",
        role: "student",
        agreedToTerms: false,
      });
      setShowModal(false);
      fetchUsers();
    } catch (error) {
      let errorMessage = "An error occurred.";
      switch (error.code) {
        case "auth/email-already-in-use":
          errorMessage = "Email address is already in use.";
          break;
        case "auth/invalid-email":
          errorMessage = "Invalid email address.";
          break;
        case "auth/weak-password":
          errorMessage = "Password is too weak.";
          break;
        default:
          errorMessage = error.message;
      }
      setLoadingU(false);
      setError(errorMessage);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await getAllStudents();
      setUsers(response);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(0); // Reset to first page on search
  };

  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleEditClick = (user) => {
    setSelectedUser(user);
    setFormData({
      name: user.name,
      email: user.email,
      phoneNumber: user.phoneNumber,
      role: user.role,
      agreedToTerms: false,
    });
    setShowModal(true);
  };

  const handleDeleteClick = (user) => {
    setSelectedUser(user);
    setShowDeleteModal(true);
  };

  const confirmDelete = async () => {
    try {
      await deleteUser(selectedUser.uid);
      setShowDeleteModal(false);
      fetchUsers();
      toast.success("User deleted successfully");
    } catch (error) {
      console.error("Error deleting user:", error);
      toast.error(`Error deleting user: ${error.message}`);
    }
  };

  const handleOpenModal = (user) => {
    setCurrentUser(user);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setCurrentUser(null);
  };

  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage;

  const filteredUsers = useMemo(
    () =>
      users.filter((user) =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [users, searchTerm]
  );

  const currentPageData = useMemo(
    () => filteredUsers.slice(offset, offset + itemsPerPage),
    [filteredUsers, offset]
  );

  const pageCount = Math.ceil(filteredUsers.length / itemsPerPage);

  return (
    <div className="md:container">
      <AdminHeader
        headerText="Users"
        descriptionText="Manage user accounts, roles, and permissions. Ensure each user has the correct access to resources and maintain the overall integrity of the system."
      />

      {loading && (
        <div
          className="flex justify-center items-center"
          style={{ height: "50vh" }}
        >
          <div className="text-center text-gray-300">
            <Spinner
              animation="border"
              className="text-textcolor"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        </div>
      )}

      {!loading && (
        <div className="bg-cardsButtons p-2 mb-4">
          <div className="projects-inner">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <Form.Control
                type="text"
                placeholder="🔍 Search by name..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="w-50  h-8 text-xs"
              />
              <Button
                variant="primary"
                className="bg-sidebar text-xs "
                onClick={() => {
                  setShowModal(true);
                  setSelectedUser(null);
                  setFormData({
                    name: "",
                    email: "",
                    phoneNumber: "",
                    role: "Student",
                    agreedToTerms: false,
                  });
                }}
              >
                <i className="fas fa-user-plus fa-xs mr-2"></i>
                Add user
              </Button>
            </div>
            <div className="table-responsive">
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border rounded-lg shadow-md">
                  <thead>
                    <tr className="bg-sidebar text-white">
                      <th className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm  ">
                        User
                      </th>
                      <th className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm hidden md:table-cell">
                        Email
                      </th>

                      <th className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm ">
                        Number
                      </th>
                      <th className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm hidden md:table-cell">
                        Role
                      </th>

                      <th className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm hidden md:table-cell">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentPageData.map((user) => (
                      <tr key={user.id} className="border-t">
                        <td className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm flex items-center">
                          <img
                            src="../../logo.PNG"
                            alt="Avatar"
                            className="hidden border p-1 md:inline-block w-10 h-10 rounded-full mr-3"
                          />
                          <p
                            className="text-gray-700"
                            onClick={() => handleOpenModal(user)}
                          >
                            {user.name}
                          </p>
                        </td>
                        <td className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm text-gray-700 hidden md:table-cell">
                          {user.email}
                        </td>
                        <td className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm text-gray-700">
                          {user.phoneNumber}
                        </td>
                        <td className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm text-gray-700 hidden md:table-cell">
                          {user.role === "student" ? (
                            <span>
                              <i className="fas fa-user-graduate fa-xs mr-2"></i>{" "}
                              {user.role}
                            </span>
                          ) : user.role === "Admin" ? (
                            <span>
                              <i className="fas fa-user-shield fa-xs mr-2"></i>{" "}
                              {user.role}
                            </span>
                          ) : (
                            user.role
                          )}
                        </td>

                        <td className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm text-gray-700 hidden md:table-cell">
                          <div className="flex">
                            <Button
                              variant="primary"
                              className="bg-sidebar text-xs mr-2"
                              onClick={() => handleEditClick(user)}
                            >
                              <i className="fas fa-pencil-alt fa-xs mr-2"></i>
                              Edit
                            </Button>
                            <Button
                              variant="primary"
                              className="bg-sidebar text-xs mr-2"
                              onClick={() => handleDeleteClick(user)}
                            >
                              <i className="fas fa-trash fa-xs mr-2"></i>
                              Delete
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {currentUser && (
                  <UserModal
                    show={isModalVisible}
                    handleClose={handleCloseModal}
                    user={currentUser}
                  />
                )}
              </div>
            </div>
            <div className="mt-4">
              <MyPagination
                currentPage={currentPage}
                pageCount={pageCount}
                handlePageClick={handlePageClick}
              />
            </div>
          </div>
        </div>
      )}

      <Modal show={showModal} size="lg" onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="font-semibold">
            {selectedUser ? "Edit User" : "Add User"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-backgroundMain">
          <div className="mx-auto max-w-screen-md">
            <form className="mb-2 p-2" onSubmit={handleSubmit}>
              {error && (
                <div
                  className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50"
                  role="alert"
                >
                  {error}
                </div>
              )}
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Name and surname
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Edumate"
                />
                {errors.name && (
                  <p className="text-red-500 mt-2 text-xs italic">
                    {errors.name}
                  </p>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Your email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="edumate@gmail.com"
                />
                {errors.email && (
                  <p className="text-red-500 mt-2 text-xs italic">
                    {errors.email}
                  </p>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="phoneNumber"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Phone number
                </label>
                <input
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="+27 602189458"
                />
                {errors.phoneNumber && (
                  <p className="text-red-500 mt-2 text-xs italic">
                    {errors.phoneNumber}
                  </p>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="role"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Role
                </label>
                <select
                  id="role"
                  name="role"
                  value={formData.role}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                >
                  <option value="student">Student</option>
                  <option value="Admin">Admin</option>
                </select>
                {errors.role && (
                  <p className="text-red-500 mt-2 text-xs italic">
                    {errors.role}
                  </p>
                )}
              </div>
              <div className="flex justify-end">
                <Button
                  variant="secondary"
                  onClick={() => setShowModal(false)}
                  className="btn signupBtn btn-sm assBtn h-7 p-2 mt-3 mr-2 shadow text-xs text-center text-[#9ca3af] btn-sm mt-1 d-flex align-items-center"
                >
                  <i className="fas fa-times mr-2"></i> Close
                </Button>
                <Button
                  variant="primary"
                  type="submit"
                  className="btn signupBtn btn-sm assBtn h-7 p-2 mt-3 shadow text-xs text-center text-[#9ca3af] btn-sm mt-1 d-flex align-items-center"
                >
                  {loadingU ? (
                    <i className="fas fa-spinner fa-spin mr-1"></i>
                  ) : (
                    <i className="fas fa-user-plus mr-2"></i>
                  )}
                  {loadingU
                    ? "Processing..."
                    : selectedUser
                    ? "Update"
                    : "Register"}
                </Button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="text-textcolor font-bold">
            Confirm
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-backgroundMain">
          Are you sure you want to delete:
          {selectedUser && selectedUser.name}?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="text-xs"
            onClick={() => setShowDeleteModal(false)}
          >
            <i className="fa fa-times fa-xs mr-2"></i> Cancel
          </Button>
          <Button
            variant="danger"
            className="text-xs"
            onClick={confirmDelete}
            disabled={loadingU} // Disable button when loading
          >
            {loadingU ? (
              <>
                <i className="fas fa-spinner fa-spin mr-1"></i> Deleting...
              </>
            ) : (
              <>
                <i className="fa fa-trash fa-xs mr-2"></i> Delete
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
