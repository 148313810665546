import React, { useState } from "react";
import Quizzes from "../Quiz/Quizzes";
import Courses from "../courses/Courses";
import Questions from "../questions/Questions";

export default function LearningHome({ onQuizClick }) {
  const [activeTab, setActiveTab] = useState("Courses");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const renderContent = () => {
    switch (activeTab) {
      case "Courses":
        return <Courses />;
      case "Quizzes":
        return <Quizzes onQuizClick={onQuizClick} />;
      case "Questions":
        return <Questions onQuizClick={onQuizClick} />;
      default:
        return <Courses />;
    }
  };

  return (
    <div className="home">
      <div className="bg-backgroundMain text-white">
        <div className="flex items-center justify-between p-4 bg-sidebar">
          <div className="flex items-center">
            <div className="p-2 rounded-full">
              <img
                src="../../../../logo.PNG"
                alt="menu icon"
                className="w-10 h-10 border-2 border-gray-300 p-1 shadow-sm"
              />
            </div>
            <nav className="ml-4">
              <ul className="flex space-x-4">
                <li>
                  <button
                    className={`flex items-center transition-colors duration-300  font-semibold leading-8 leading-loose ${
                      activeTab === "Courses"
                        ? "text-[#709fa8]"
                        : "text-gray-300 hover:text-gray-400"
                    }`}
                    onClick={() => handleTabChange("Courses")}
                  >
                    <i className="fas fa-book mr-2 fa-xs"></i>
                    Courses
                  </button>
                </li>
                <li>
                  <button
                    className={`flex items-center transition-colors duration-300  font-semibold leading-8 leading-loose ${
                      activeTab === "Quizzes"
                        ? "text-[#709fa8]"
                        : "text-gray-300 hover:text-gray-400"
                    }`}
                    onClick={() => handleTabChange("Quizzes")}
                  >
                    <i className="fas fa-clipboard mr-2 fa-xs"></i>
                    Quizzes
                  </button>
                </li>
                <li>
                  <button
                    className={`flex items-center transition-colors duration-300  font-semibold leading-8 leading-loose ${
                      activeTab === "Quizzes"
                        ? "text-[#709fa8]"
                        : "text-gray-300 hover:text-gray-400"
                    }`}
                    onClick={() => handleTabChange("Questions")}
                  >
                    <i className="fas fa-clipboard mr-2 fa-xs"></i>
                    Questions
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="p-4 bg-cardsButtons">
          <div className="text-lg font-extrabold text-textcolor mt-2">
            <i className="fas fa-graduation-cap mr-2"></i>
            Edumate Learning
          </div>
          <div className="items-center mt-2">
            <div className="w-full">
              <p className="text-textcolor text-xl">
                Welcome to Edumate Learning! Explore a variety of courses, take
                engaging quizzes, and track your progress. Enhance your learning
                experience with our intuitive tools and interactive features.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-5">{renderContent()}</div>
    </div>
  );
}
