export const initializePaystackPayment = ({
  email,
  amount,
  reference,
  onSuccess,
  onClose,
}) => {
  const publicKey = process.env.REACT_APP_PAYSTACK_LIVE_SECRET_KEY;
  if (!publicKey) {
    return;
  }

  const handler = window.PaystackPop.setup({
    key: publicKey,
    email: email,
    amount: amount * 100,
    currency: "ZAR",
    ref: reference || `${Math.floor(Math.random() * 1000000000)}`,
    callback: onSuccess,
    onClose: onClose,
  });
  handler.openIframe();
};

export const initializeTransaction = async (email, amount, plan) => {
  const url = "https://api.paystack.co/transaction/initialize";
  const params = {
    email: email,
    amount: amount * 100,
    plan: plan,
  };

  const apiKey = process.env.REACT_APP_PAYSTACK_TEST_SECRET_KEY;

  const options = {
    method: "POST",
    headers: {
      Authorization: `Bearer ${apiKey}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  };
  try {
    const response = await fetch(url, options);
    const data = await response.json();
    if (!response.ok) {
      const errorMessage =
        data.message || `${response.status} - ${response.statusText}`;
      throw new Error(`HTTP Error: ${errorMessage}`);
    }
    return data;
  } catch (error) {
    return error;
  }
};
