import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useUser } from "../../../context/AuthContext";
import { fetchAssignmentsByUserId } from "../../../firebase/Functions";

export default function Default() {
  const [user, setUser] = useState(null);
  const { getUserByUid, extractUid } = useUser();
  const [assignments, setAssignments] = useState([]);

  const [show, setShow] = useState(false);
  const [isServiceRatesVisible, setIsServiceRatesVisible] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const toggleServiceRates = () =>
    setIsServiceRatesVisible(!isServiceRatesVisible);

  const fetchAssignments = async (user) => {
    try {
      const assignmentList = await fetchAssignmentsByUserId(user.uid);
      setAssignments(assignmentList);
    } catch (error) {
      console.error("Error fetching assignments:", error);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userJSON = localStorage.getItem("user");
        if (userJSON) {
          const userData = JSON.parse(userJSON);
          const currentUser = await getUserByUid(extractUid(userData));
          setUser(currentUser);
          fetchAssignments(currentUser);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUser();
  }, [getUserByUid, extractUid]);

  return (
    <div className="md:container">
      <div className="bg-backgroundMain text-white">
        <div className="flex items-center justify-between p-4 bg-sidebar">
          <div className="flex items-center">
            <div className="p-2 rounded-full">
              <img
                src="../../../../logo.PNG"
                alt="menu icon"
                className="w-10 h-10 border-2 border-gray-300 p-1 shadow-sm"
              />
            </div>
            <h2 className="text-cardsButtons text-2xl font-extrabold">
              Dashboard
            </h2>
          </div>
        </div>
        <div className="bg-cardsButtons p-2  pb-3">
          <section className="mb-3 mt-2">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
              <div className="bg-yellow-100 p-4 text-center">
                <p className="text-2xl font-bold text-yellow-600">
                  {assignments.length}
                </p>

                <p className="text-zinc-600 dark:text-zinc-400">Assignments</p>
              </div>
              <div className="bg-purple-100 p-4 text-center">
                <p className="text-2xl font-bold text-purple-600">00%</p>
                <p className="text-zinc-600">Quizzes</p>
              </div>
              <div className="bg-pink-100 p-4 text-center">
                <p className="text-2xl font-bold text-pink-600 dark:text-pink-300">
                  00
                </p>
                <p className="text-zinc-600 dark:text-zinc-400">Courses</p>
              </div>
            </div>
          </section>
          <Button
            variant="secondary"
            onClick={toggleServiceRates}
            className="toggle-button text-xs mb-3"
          >
            <i
              className={`fa ${
                isServiceRatesVisible ? "fa-eye-slash mr-2 " : "fa-eye mr-2"
              }`}
              aria-hidden="true"
            ></i>
            Show Rates
          </Button>
          <div className={` ${isServiceRatesVisible ? "" : "hidden"}`}>
            <section>
              <div className="row -mt-1">
                <div className="col-md-6">
                  <table className="projects-table">
                    <thead>
                      <tr>
                        <th>Assignment</th>
                        <th>Description</th>
                        <th>Price (ZAR)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Simple Code-Based</td>
                        <td>Basic coding assignments</td>
                        <td>R120</td>
                      </tr>
                      <tr>
                        <td>Complex Code-Based</td>
                        <td>Advanced coding assignments</td>
                        <td>R150</td>
                      </tr>
                      <tr>
                        <td>Data Analysis Projects</td>
                        <td>Code with visualizations</td>
                        <td>R100 per page</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>Interpretation</td>
                        <td>R70 per page</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>Code + Interpretation</td>
                        <td>R200</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-md-6">
                  <table className="projects-table">
                    <thead>
                      <tr>
                        <th>Assignment</th>
                        <th>Description</th>
                        <th>Price (ZAR)</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Essays</td>
                        <td>1500 words</td>
                        <td>R50 per page</td>
                      </tr>
                      <tr>
                        <td>Multiple Choice Questions</td>
                        <td>Each question</td>
                        <td>R5 per question</td>
                      </tr>
                      <tr>
                        <td>Referencing</td>
                        <td>APA, MLA, etc.</td>
                        <td>Additional R20 per page</td>
                      </tr>
                      <tr>
                        <td>Formatting</td>
                        <td>Formatting requirements</td>
                        <td>Additional R15 per page</td>
                      </tr>
                      <tr>
                        <td>Short Notice</td>
                        <td>Urgent requests</td>
                        <td>Additional R30 per page</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
