import React, { useEffect, useState } from "react";
import {
  fetchAssignmentById,
  uploadZipFileToAssignment,
} from "../../../firebase/Functions";
import { Spinner, Button } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function AssignmentDetails({ assignmentId, handleCloseModal }) {
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (file) {
      setUploading(true);
      uploadZipFileToAssignment(selectedAssignment, file, setProgress)
        .then(() => {
          handleCloseModal();
          toast.success("File uploaded successfully!");
        })
        .catch((error) => {
          toast.error("Error uploading file: " + error.message);
        })
        .finally(() => {
          setUploading(false);
        });
    } else {
      toast.warn("Please select a .zip file to upload.");
    }
  };

  useEffect(() => {
    const getAssignment = async () => {
      try {
        const assignment = await fetchAssignmentById(assignmentId);
        console.log(assignment);
        setSelectedAssignment(assignment);
      } catch (error) {
        console.error("Error fetching assignment:", error);
      } finally {
        setLoading(false);
      }
    };
    getAssignment();
  }, [assignmentId]);

  const handleDownload = () => {
    // Extract the file URL from selectedAssignment
    const fileUrl = selectedAssignment.files[0].fileUrl;

    window.open(fileUrl, "_blank");
  };

  return (
    <div className="container">
      <ToastContainer />
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "50vh" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        selectedAssignment && (
          <>
            <form
              className="mt-1 shadow-md p-3 mb-2"
              id={assignmentId}
              onSubmit={handleSubmit}
            >
              <input
                className="block w-full text-sm text-gray-900 rounded py-1 px-1 mt-1 cursor-pointer bg-gray-50 focus:outline-none "
                aria-describedby="user_avatar_help"
                type="file"
                accept=".zip"
                onChange={handleFileChange}
              />
              <Button
                type="submit"
                className="btn signupBtn btn-sm assBtn h-7 p-2 mt-3 shadow text-xs text-center text-[#9ca3af] btn-sm mt-1 d-flex align-items-center"
                disabled={uploading}
              >
                {uploading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                    className="mr-2"
                  />
                ) : (
                  <i className="fas fa-upload fa-xs mr-2"></i>
                )}
                {uploading ? "Uploading..." : "Upload"}
              </Button>
            </form>
            <div className="flex-grow ">
              <div className="p-3 shadow-md">
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <h3 className="text-lg font-bold text-textcolor">
                    {selectedAssignment.module}
                  </h3>
                  {selectedAssignment.answerUrl ? (
                    <span className="text-success d-flex align-items-center">
                      <i className="fas fa-check-circle mr-2"></i> Solution
                      Available
                    </span>
                  ) : (
                    <span className="text-warning d-flex align-items-center">
                      <i className="fas fa-exclamation-circle mr-2"></i> No
                      Solution Yet
                    </span>
                  )}
                </div>
                <hr className="text-textcolor"></hr>
                <p className="mt-2 mb-2 text-textcolor">
                  {selectedAssignment.extraInstruction}
                </p>
                <hr className="text-text-textcolor mb-2"></hr>
                <p className="text-textcolor text-xs">
                  <i className="far fa-clock  mr-2"></i>Due date:
                  {selectedAssignment.date}
                </p>

                <Button
                  href={selectedAssignment.files[0].fileUrl}
                  className="btn signupBtn btn-sm assBtn h-7 p-2 mt-3 shadow text-xs text-center text-[#9ca3af] btn-sm mt-1 d-flex align-items-center"
                >
                  <i className="fas fa-download mr-2"></i> Download
                </Button>
              </div>
            </div>
          </>
        )
      )}
    </div>
  );
}
