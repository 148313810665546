import React from "react";
import { Spinner } from "react-bootstrap";

export default function Courses() {
  return (
    <div className="md:container">
      <div className="text-textcolor">
        <div className="flex justify-between items-center mb-3 mt-3">
          <h2 className="text-textcolor p-1 text-xl font-bold">
            <i className="fas fa-list-ul mr-2"></i> Courses
          </h2>
        </div>
        <div className="bg-cardsButtons py-16">
          <div className="text-center">
            <p className="text-textcolor text-2xl font-bold">Coming Soon</p>
            <p className="text-textcolor text-sm">
              Stay tuned for our exciting courses!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
