import {
  getFirestore,
  doc,
  collection,
  addDoc,
  arrayUnion,
  updateDoc,
  getDocs,
  getDoc,
  query,
  where,
  deleteDoc,
} from "firebase/firestore";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

import { v4 as uuidv4 } from "uuid";

const db = getFirestore();
const storage = getStorage();

export const saveFormData = async (formData, userId, setUploadProgress) => {
  try {
    const uniqueFolderId = uuidv4();
    const docRef = await addDoc(collection(db, "assignments"), {
      module: formData.module,
      userId: userId,
      date: formData.date,
      time: formData.time,
      extraInstruction: formData.extraInstruction,
      folderId: uniqueFolderId,
      question: true,
      answer: false,
    });

    const filePath = `assignments/${userId}/${uniqueFolderId}/${formData.file.name}`;
    const fileRef = ref(storage, filePath);

    const uploadTask = uploadBytesResumable(fileRef, formData.file, {
      contentType: "application/zip",
    });

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        setUploadProgress(0);
      },
      async () => {
        try {
          const fileUrl = await getDownloadURL(fileRef);
          await updateDoc(docRef, {
            files: arrayUnion({
              fileUrl,
              uploadedAt: new Date().toISOString(),
            }),
          });
        } catch (error) {
          await deleteUploadedFile(fileRef);
        }
      }
    );

    return true;
  } catch (error) {
    return false;
  }
};

// Saves transaction and updates assignment
export const saveTransactionAndUpdateAssignment = async (
  email,
  module,
  amount,
  reference,
  assignmentsId
) => {
  let transactionDocRef;
  try {
    const uniqueFolderId = uuidv4();
    transactionDocRef = await addDoc(collection(db, "transactions"), {
      email,
      module,
      amount,
      reference,
      folderId: uniqueFolderId,
    });

    if (transactionDocRef && assignmentsId) {
      const assignmentDocRef = doc(db, "assignments", assignmentsId);
      const assignmentDocSnapshot = await getDoc(assignmentDocRef);
      if (assignmentDocSnapshot.exists()) {
        await updateDoc(assignmentDocRef, { paid: true });
      } else {
        throw new Error("Assignment document not found");
      }
    } else {
      throw new Error("Invalid transaction or assignment ID");
    }

    return transactionDocRef;
  } catch (error) {
    if (transactionDocRef) {
      await deleteDoc(transactionDocRef);
    }
    throw error;
  }
};

// Retrieves transactions
export const getTransactions = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "transactions"));
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    throw error;
  }
};

// Deletes an assignment document from Firestore and its associated file from Storage
export const deleteAssignmentAndFile = async (assignment) => {
  try {
    if (assignment.files && assignment.files.length > 0) {
      const fileUrl = assignment.files[0].fileUrl;
      if (fileUrl) {
        const filePath = extractFilePathFromUrl(fileUrl);
        const fileRef = ref(storage, filePath);
        await deleteObject(fileRef);
      }
    }
    await deleteDoc(doc(db, "assignments", assignment.id));
  } catch (error) {
    throw error;
  }
};

const extractFilePathFromUrl = (url) => {
  const baseUrl =
    "https://firebasestorage.googleapis.com/v0/b/oblivision-dbd1c.appspot.com/o/";
  const startIndex = baseUrl.length;
  const endIndex = url.indexOf("?alt=media");
  return decodeURIComponent(url.substring(startIndex, endIndex));
};

// Uploads a .zip file to an assignment
export const uploadZipFileToAssignment = async (
  assignment,
  file,
  onProgress
) => {
  try {
    const { userId, folderId, id: assignmentId } = assignment;

    if (!userId || !folderId || !assignmentId) {
      throw new Error("Missing required assignment details.");
    }

    if (!file || !file.name.endsWith(".zip")) {
      throw new Error("No .zip file provided for upload.");
    }

    const filePath = `assignments/${userId}/${folderId}/${file.name}`;
    const fileRef = ref(storage, filePath);
    const uploadTask = uploadBytesResumable(fileRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress(progress);
      },
      (error) => {},
      async () => {
        const fileUrl = await getDownloadURL(uploadTask.snapshot.ref);
        await updateDoc(doc(db, "assignments", assignmentId), {
          answerUrl: fileUrl,
          answer: true,
        });

        const assignmentDocRef = doc(db, "assignments", assignmentId);
        const assignmentDoc = await getDoc(assignmentDocRef);
        if (assignmentDoc.exists()) {
          const assignmentData = assignmentDoc.data();
          if (!assignmentData.hasOwnProperty("paid")) {
            await updateDoc(assignmentDocRef, { paid: false });
          }
        }
      }
    );
  } catch (error) {
    throw error;
  }
};

// Deletes an uploaded file from Storage
const deleteUploadedFile = async (fileRef) => {
  try {
    await deleteObject(fileRef);
  } catch (error) {}
};

// Updates the price of an assignment
export const updatePrice = async (formData, assignmentId) => {
  try {
    await updateDoc(doc(db, "assignments", assignmentId), {
      price: formData.price,
    });
    return true;
  } catch (error) {
    return false;
  }
};

// Fetches an assignment by its ID from Firestore
export const fetchAssignmentById = async (assignmentId) => {
  try {
    const docRef = doc(db, "assignments", assignmentId);
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      return { id: docSnapshot.id, ...docSnapshot.data() };
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

// Fetches assignments by user ID from Firestore
export const fetchAssignmentsByUserId = async (userId) => {
  try {
    const assignmentsRef = collection(db, "assignments");
    const q = query(assignmentsRef, where("userId", "==", userId));
    const querySnapshot = await getDocs(q);

    const assignments = [];
    querySnapshot.forEach((doc) => {
      assignments.push({ id: doc.id, ...doc.data() });
    });

    return assignments;
  } catch (error) {
    return [];
  }
};

// Fetches all assignments from Firestore
export const fetchAllAssignments = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "assignments"));
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    return [];
  }
};

// Gets the role of a user
export async function getUserRole(user) {
  const uid = user.uid;
  try {
    const docRef = doc(db, "students", uid);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      const userData = docSnapshot.data();
      const role = userData.role;
      return role;
    } else {
      throw new Error("User document does not exist");
    }
  } catch (error) {
    throw error;
  }
}

// Saves appointment data
export const saveAppointmentData = async (uid, formData) => {
  try {
    await addDoc(collection(db, "appointments"), {
      appointmentTitle: formData.appointmentTitle,
      startTime: formData.startTime,
      endTime: formData.endTime,
      duration: formData.duration,
      venue: formData.venue,
      date: formData.date,
      person: formData.person,
      userId: uid,
    });

    return true;
  } catch (error) {
    return false;
  }
};

// Updates appointment completion status
export const updateAppointmentCompletion = async (appId, completedStatus) => {
  try {
    const appointmentRef = doc(db, "appointments", appId);
    await updateDoc(appointmentRef, { completed: completedStatus });

    return true;
  } catch (error) {
    return false;
  }
};

// Gets appointments by user ID
export const getAppointmentByUid = async (uid) => {
  try {
    const q = query(collection(db, "appointments"), where("userId", "==", uid));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    return [];
  }
};
