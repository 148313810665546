import React from "react";
import { Link } from "react-router-dom";

export default function Hero() {
  return (
    <div>
      <section className="bg-gray-200 common-margin">
        <div className="grid max-w-screen-xl px-4 py-8  mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl text-[#719fa9] font-extrabold tracking-tight leading-none md:text-5xl xl:text-5xl ">
              Expert Academic Assistance
            </h1>
            <p className="max-w-2xl mb-6 text-gray-500 text-lg font-normal leading-normal text-gray-500 lg:text-xl">
              Edumate offers expert academic assistance for research papers,
              essays, and problem sets. Our team provides high-quality support
              tailored to your needs. We also offer personalized tutoring in any
              subject, online or in person, to enhance your academic journey
              with precision and insightful feedback. Reach out with your
              assignment details or tutoring needs and achieve academic success
              with us. <br />
              <span className="mt-2 text-[#719fa9]">
                Your Partner in Academic Excellence.
              </span>
            </p>

            <Link
              to="/signup"
              className="btn btn-primary bg-[#719fa9] w-40 inline-flex items-center border border-gray-700 justify-center px-3 py-2 mr-3 text-sm md:px-5 md:py-3 text-white font-medium text-center rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 "
            >
              Get started
              <svg
                className="w-4 h-4 ml-2 -mr-1"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
          <div className="hidden lg:mt-0 lg:col-span-4 lg:flex">
            <img src="../../../../Thesis-pana.svg" alt="mockup" />
          </div>
        </div>
      </section>
    </div>
  );
}
