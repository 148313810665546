import React, { useState, useEffect } from "react";
import { Button, Spinner, Modal } from "react-bootstrap";
import { getAllQuizzes, deleteQuizById } from "../../../firebase/Quiz";
import QuizView from "./QuizView";
import AddQuiz from "./AddQuiz"; // Import your AddQuiz component
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function QuizList() {
  const [quizzes, setQuizzes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedQuiz, setSelectedQuiz] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showAddQuiz, setShowAddQuiz] = useState(false); // State to toggle between quiz list and add quiz form

  useEffect(() => {
    fetchQuizzes();
  }, []);

  const fetchQuizzes = async () => {
    try {
      setLoading(true);
      const fetchedQuizzes = await getAllQuizzes();
      setQuizzes(fetchedQuizzes);
    } catch (error) {
      console.error("Error fetching quizzes:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleImageClick = (quiz) => {
    setSelectedQuiz(quiz);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedQuiz(null);
  };

  const handleDelete = async (quizId) => {
    const confirmed = window.confirm(
      "Are you sure you want to delete this quiz?"
    );

    if (confirmed) {
      const success = await deleteQuizById(quizId);
      if (success) {
        toast.success("Quiz deleted successfully!");
        fetchQuizzes();
      } else {
        toast.error("Failed to delete quiz. Please try again.");
      }
    }
  };

  const toggleAddQuiz = () => {
    setShowAddQuiz(!showAddQuiz);
  };

  return (
    <div className="admin">
      <div className="text-textcolor mb-6">
        <div className="flex justify-between mt-3 items-center mb-3">
          <h2 className="text-lg font-extrabold p-2 text-textcolor">
            <i className="fas fa-list-ul mr-2"></i> Quizzes
          </h2>
          <div className="flex space-x-4">
            <Button
              variant="primary"
              className="bg-sidebar text-xs mr-2"
              onClick={toggleAddQuiz}
            >
              <i
                className={`fas ${
                  showAddQuiz ? "fa-list-ul" : "fa-plus-circle"
                } mr-2 fa-xs`}
              ></i>
              {showAddQuiz ? "Show Quizzes" : "Add Quiz"}
            </Button>
          </div>
        </div>
        <div className="card-body">
          {showAddQuiz ? (
            <AddQuiz />
          ) : loading ? (
            <div className="text-center">
              <Spinner
                animation="border"
                className="text-textcolor"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            quizzes.map((quiz) => (
              <div
                key={quiz.id}
                className="flex justify-between items-center bg-cardsButtons py-3 mb-3"
              >
                <div className="flex items-center">
                  <img
                    src="../../../logo.PNG"
                    alt="Avatar"
                    className="border p-1 ml-2 md:inline-block w-10 h-10 mr-3 cursor-pointer"
                    onClick={() => handleImageClick(quiz)}
                  />
                  <div>
                    <p
                      onClick={() => handleImageClick(quiz)}
                      className="text-textcolor text-xl md:text-lg sm:text-base cursor-pointer"
                    >
                      {quiz.quizName}
                    </p>

                    <p className="text-textcolor text-xs">{quiz.grade}</p>
                  </div>
                </div>
                <div className="flex space-x-4 items-center">
                  <div className="flex">
                    <Button
                      variant="primary"
                      className="bg-sidebar text-xs mr-2"
                    >
                      <i className="fas fa-pencil-alt fa-sm mr-2"></i>
                      Edit
                    </Button>
                    <Button
                      variant="primary"
                      className="bg-sidebar text-xs mr-2"
                      onClick={() => handleDelete(quiz.id)}
                    >
                      <i className="fas fa-trash fa-sm mr-2"></i>
                      Delete
                    </Button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      <Modal show={showModal} size="lg" onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title className="text-2xl font-semibold text-textcolor">
            {selectedQuiz && selectedQuiz.quizName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-backgroundMain">
          {selectedQuiz && <QuizView quizId={selectedQuiz.id} />}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={closeModal}
            className="btn signupBtn btn-sm assBtn h-7 p-2 mt-3 shadow text-xs text-center text-[#9ca3af] btn-sm mt-1 d-flex align-items-center"
          >
            <i className="fas fa-times fa-xs mr-2"></i>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
