import React, { useState } from "react";
import { Button } from "react-bootstrap";
import CourseList from "./CourseList";
import AddCourse from "./AddCourse";

export default function Courses() {
  const [showAddCourse, setShowAddCourse] = useState(false);

  return (
    <div className="md:container">
      <div className="text-textcolor mt-3 mb-6">
        <div className="flex justify-between  items-center mb-3">
          <h2 className="text-lg font-extrabold p-2 text-textcolor">
            <i className="fas fa-list-ul mr-2"></i> Courses
          </h2>
          <div className="flex space-x-4">
            <Button
              variant="primary"
              className="bg-sidebar text-xs mr-2"
              onClick={() => setShowAddCourse(!showAddCourse)}
            >
              <i
                className={`fas ${
                  showAddCourse ? "fa-arrow-circle-left" : "fa-plus-circle"
                } mr-2 fa-xs`}
              ></i>
              {showAddCourse ? "Show Courses" : "Add Course"}
            </Button>
          </div>
        </div>
        <div className="content">
          {showAddCourse ? <AddCourse /> : <CourseList />}
        </div>
      </div>
    </div>
  );
}
