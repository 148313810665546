import { useState } from "react";

export default function Notifications() {
  const [activeInput, setActiveInput] = useState(null);
  const [message, setMessage] = useState("");

  const handleSend = async () => {
    alert(`Sending ${activeInput}: ${message}`);
    setMessage("");
    setActiveInput(null);
  };
  return (
    <div className="md:container ">
      <div className="bg-backgroundMain text-white">
        <div className="flex items-center justify-between p-4 bg-sidebar">
          <div className="flex items-center">
            <div className="p-2 rounded-full">
              <img
                src="../../../../logo.PNG"
                alt="menu icon"
                className="w-10 h-10 border-2 border-gray-300 p-1 shadow-sm"
              />
            </div>
            <nav className="ml-4">
              <ul className="flex space-x-4">
                <li>
                  <button className="flex items-center font-semibold leading-8 transition-colors duration-300">
                    <i className="fas fa-bullhorn mr-2 fa-xs"></i> Promote
                  </button>
                </li>
                <li>
                  <button className="flex items-center font-semibold leading-8 transition-colors duration-300">
                    <i className="fas fa-bell mr-2 fa-xs"></i> Notify
                  </button>
                </li>
                <li>
                  <button className="flex items-center font-semibold leading-8 transition-colors duration-300">
                    <i className="fas fa-envelope mr-2 fa-xs"></i> Message
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="p-3 bg-cardsButtons mb-3">
          <div className="text-2xl font-bold text-textcolor mt-2 md:text-xl sm:text-lg">
            <i className="fas fa-graduation-cap mr-2"></i>
            Edumate Learning
          </div>
          <div className="items-center mt-2">
            <div className="w-full">
              <p className="text-textcolor text-xl md:text-lg sm:text-base">
                Welcome to Edumate Learning Hub! Easily create quizzes and
                courses. Enhance teaching with intuitive tools and make learning
                interactive with Edumate!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
