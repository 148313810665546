import React, { useState, useEffect } from "react";
import { Spinner, Button, Modal } from "react-bootstrap";
import { useUser } from "../../../context/AuthContext";
import AssignmentData from "./partials/AssignmentData";
import UpdateAssignmentModal from "./partials/UpdateAssignmentModal";
import {
  fetchAssignmentsByUserId,
  saveFormData,
  deleteAssignmentAndFile,
} from "../../../firebase/Functions";
import { toast } from "react-toastify";

export default function Assignment() {
  const [user, setUser] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingA, setLoadingA] = useState(false);
  const [error, setError] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0);

  const [assignments, setAssignments] = useState([]);
  const { extractUid, getUserByUid } = useUser();

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [assignmentToUpdate, setAssignmentToUpdate] = useState(null);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateError, setUpdateError] = useState(null);

  const [formData, setFormData] = useState({
    module: "",
    file: null,
    date: "",
    time: "",
    extraInstruction: "",
  });

  const [searchQuery, setSearchQuery] = useState("");

  const handleClose = () => {
    setFormData({
      module: "",
      file: null,
      date: "",
      time: "",
      extraInstruction: "",
    });
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const fetchUser = async () => {
    try {
      const userJSON = localStorage.getItem("user");
      if (userJSON) {
        const userData = JSON.parse(userJSON);
        const currentUser = await getUserByUid(extractUid(userData));
        setUser(currentUser);
        fetchAssignments(currentUser);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUser();
  }, [getUserByUid, extractUid]);

  const fetchAssignments = async (user) => {
    try {
      setLoading(true);
      const assignmentList = await fetchAssignmentsByUserId(user.uid);
      setAssignments(assignmentList);
    } catch (error) {
      console.error("Error fetching assignments:", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteAssignmentAndFetchData = async (assignment) => {
    try {
      await deleteAssignmentAndFile(assignment);
      await fetchUser();
      toast.success("Assignment deleted successfully!");
    } catch (error) {
      toast.error("Error deleting assignment. Please try again.");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    if (file.size > 5 * 1024 * 1024) {
      setError("File size should not exceed 5MB");
    } else if (!file.name.endsWith(".zip")) {
      setError("File must be a .zip file");
    } else {
      setFormData({ ...formData, file });
      setError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      !formData.module ||
      !formData.file ||
      !formData.date ||
      !formData.time
    ) {
      setError("All fields are required");
      return;
    }

    try {
      setLoadingA(true);
      const result = await saveFormData(formData, user.uid, setUploadProgress);
      if (result) {
        setLoading(false);
        handleClose();
        toast.success("File uploaded successfully!");
        fetchAssignments(user);
        setLoadingA(false);
      } else {
        console.error("Failed to save form data");
      }
    } catch (error) {
      console.error("Error saving form data:", error);
      setLoadingA(false);
    }

    setFormData({
      module: "",
      file: null,
      date: "",
      time: "",
      extraInstruction: "",
    });
    setError("");
  };

  const handleUpdate = (assignment) => {
    setAssignmentToUpdate(assignment);

    setFormData({
      module: assignment.module,
      file: null,
      date: assignment.date,
      time: assignment.time,
      extraInstruction: assignment.extraInstruction,
    });
    setShowUpdateModal(true);
  };

  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    setAssignmentToUpdate(null);
    setUpdateError(null);
    setFormData({
      module: "",
      file: null,
      date: "",
      time: "",
      extraInstruction: "",
    });
  };

  const handleSubmitUpdate = async (e) => {
    e.preventDefault();
    try {
      setUpdateLoading(true);
      if (!formData.module || !formData.date || !formData.time) {
        setUpdateError("Please fill in all required fields.");
      } else {
        setUpdateError(null);
        toast.success("Assignment updated successfully!");
        handleCloseUpdateModal();
      }
    } catch (error) {
      setUpdateError("Failed to update assignment. Please try again.");
    } finally {
      setUpdateLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredAssignments = assignments.filter((assignment) =>
    assignment.module.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "80vh" }}
      >
        <Spinner animation="border" className="text-textcolor" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <section className="md:container p-1">
      <div className="bg-backgroundMain text-white">
        <div className="flex items-center justify-between p-4 bg-sidebar">
          <div className="flex items-center">
            <div className="p-2 rounded-full">
              <img
                src="../../../../logo.PNG"
                alt="menu icon"
                className="w-10 h-10 border-2 border-gray-300 p-1 shadow-sm"
              />
            </div>
            <h2 className=" text-cardsButtons text-2xl font-bold">
              Assignments
            </h2>
          </div>
        </div>
        <div className="p-4 bg-cardsButtons mb-3">
          <div className="text-lg font-extrabold text-textcolor mt-2">
            <i className="fas fa-graduation-cap mr-2"></i>
            Edumate
          </div>
          <div className="items-center mt-2">
            <div className="w-full">
              <p className="text-textcolor text-xl">
                Please upload assignments in zip format, under 5MB. Thank you!
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="mx-auto flex justify-between pb-3 items-center rounded-md">
        <div className="relative hidden sm:block">
          <input
            type="text"
            className="py-3 pl-8 pr-8 pr-2 h-9 w-80 text-xs border focus:outline-none focus:border-blue-500"
            placeholder="Search by module..."
            aria-describedby="basic-addon1"
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
            <i className="fas fa-search text-gray-400"></i>
          </div>
        </div>
        <button
          onClick={handleShow}
          className="btn btn-sm bg-cardsButtons border border-border text-textcolor px-3 py-2  hover:bg-cardsButtons"
        >
          <i className="fas fa-plus mr-2"></i>
          Assignment
        </button>
      </div>

      {filteredAssignments.length > 0 ? (
        filteredAssignments.map((assignment, index) => (
          <AssignmentData
            key={index}
            assignment={assignment}
            deleteAssignmentAndFetchData={deleteAssignmentAndFetchData}
            handleUpdate={handleUpdate}
          />
        ))
      ) : (
        <div className="bg-cardsButtons py-16">
          <div className="text-center">
            <p className="text-textcolor text-2xl font-bold">No assignment</p>
            <p className="text-textcolor text-sm">
              You haven't uploaded any assignments yet.
            </p>
          </div>
        </div>
      )}

      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="font-semibold">Upload Assignment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {error && (
            <div
              className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
              role="alert"
            >
              {error}
            </div>
          )}

          <form className="text-textcolor" onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                className="shadow-sm text-textcolor border text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                placeholder="Module"
                name="module"
                value={formData.module}
                onChange={handleChange}
              />
            </div>
            <div className="form-group mt-2">
              <input
                type="file"
                className="shadow-sm border text-textcolor text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                accept=".zip"
                onChange={handleFileChange}
              />
            </div>
            <div className="form-group mt-2">
              <input
                type="date"
                className="shadow-sm border text-textcolor text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-custom"
                placeholder="Pick due date"
                name="date"
                value={formData.date}
                onChange={handleChange}
              />
            </div>
            <div className="form-group mt-2">
              <input
                type="time"
                className="shadow-sm border text-textcolor text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 placeholder-custom"
                placeholder="Pick due time"
                name="time"
                value={formData.time}
                onChange={handleChange}
              />
            </div>
            <div className="form-group mt-2">
              <textarea
                className="shadow-sm border text-textcolor text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                placeholder="Extra Instructions"
                name="extraInstruction"
                value={formData.extraInstruction}
                onChange={handleChange}
              ></textarea>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleSubmit}
            className="btn btn-secondary signupBtn assBtn h-10 p-2 shadow text-xs text-center text-[#9ca3af] mt-2 d-flex align-items-center"
          >
            {loadingA ? (
              <>
                <i className="fas fa-spinner fa-spin fa-xs mr-1"></i>
                Uploading...
              </>
            ) : (
              <>
                <i className="fas fa-upload fa-xs mr-2"></i>
                Upload
              </>
            )}
          </Button>
          <Button
            variant="secondary"
            className="btn btn-secondary signupBtn assBtn h-10 p-2 shadow text-xs text-center text-[#9ca3af] mt-1 d-flex align-items-center"
            onClick={handleClose}
          >
            <i className="fas fa-times fa-xs mr-2"></i>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <UpdateAssignmentModal
        show={showUpdateModal}
        handleClose={handleCloseUpdateModal}
        handleSubmit={handleSubmitUpdate}
        assignmentData={assignmentToUpdate}
        loading={updateLoading}
        error={updateError}
      />
    </section>
  );
}
