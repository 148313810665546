import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { getTransactions } from "../../firebase/Functions";
import { Form } from "react-bootstrap";
import AdminHeader from "../../components/common/header/AdminHeader";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

export default function Transactions() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const fetchedTransactions = await getTransactions();
        setTransactions(fetchedTransactions);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching transactions:", error);
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  const filteredTransactions = transactions.filter((transaction) =>
    transaction.reference.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="md:container">
      <div className="row p-0">
        {loading && (
          <div
            className="flex justify-center items-center"
            style={{ height: "60vh" }}
          >
            <div className="text-center text-gray-300">
              <Spinner
                animation="border"
                className="text-textcolor"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          </div>
        )}
        {!loading && (
          <div className="col-md-12">
            <div className="">
              <div className="projects mb-4">
                <div className="projects-inner">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <Form.Control
                      type="text"
                      placeholder="🔍 Search by reference number..."
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="w-50 h-8 text-xs"
                    />
                  </div>

                  {filteredTransactions.length === 0 ? (
                    <p>No transactions data available</p>
                  ) : (
                    <div className="table-responsive">
                      <div className="overflow-x-auto">
                        <table className="min-w-full bg-white border rounded-lg shadow-md">
                          <thead>
                            <tr className="bg-sidebar text-white">
                              <th className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm hidden md:table-cell">
                                Email
                              </th>
                              <th className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm ">
                                Module
                              </th>
                              <th className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm hidden md:table-cell">
                                Amount
                              </th>
                              <th className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm ">
                                Reference
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredTransactions.map((transaction) => (
                              <tr key={transaction.id} className="border-t">
                                <td className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm hidden md:table-cell">
                                  <p className="text-gray-700">
                                    {transaction.email}
                                  </p>
                                </td>
                                <td className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm text-gray-700">
                                  {transaction.module}
                                </td>
                                <td className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm text-gray-700 hidden md:table-cell">
                                  R {transaction.amount}
                                </td>
                                <td className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm text-gray-700">
                                  {transaction.reference}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
