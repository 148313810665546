import React from "react";
import { Modal, Button } from "react-bootstrap";

export default function UpdateAssignmentModal({
  show,
  handleClose,
  handleSubmit,
  handleChange,
  handleFileChange,
  assignmentData,
  loading,
  error,
}) {
  return (
    <Modal show={show} size="lg" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Update Assignment</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-backgroundMain">
        <div
          id="alert-additional-content-1"
          className="features p-4 mb-3 text-black"
          role="alert"
        >
          <div className="flex items-center">
            <svg
              className="flex-shrink-0 w-5 h-5 me-2 text-gray-300"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
            <span className="sr-only">Info</span>
            <h3 className="text-lg font-medium text-gray-300">
              Upload files in zip format (.zip)
            </h3>
          </div>
        </div>

        {error && (
          <div
            className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
            role="alert"
          >
            {error}
          </div>
        )}

        <form className="features p-3" onSubmit={handleSubmit}>
          <div className="form-group">
            <input
              type="text"
              className="shadow-sm text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Module"
              name="module"
              value={assignmentData?.module || ""}
              onChange={handleChange}
            />
          </div>
          <div className="form-group mt-2">
            <input
              type="file"
              className="shadow-sm text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              accept=".zip"
              onChange={handleFileChange}
            />
          </div>
          <div className="form-group mt-2">
            <input
              type="date"
              className="shadow-sm text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              name="date"
              value={assignmentData?.date || ""}
              onChange={handleChange}
            />
          </div>
          <div className="form-group mt-2">
            <input
              type="time"
              className="shadow-sm text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              name="time"
              value={assignmentData?.time || ""}
              onChange={handleChange}
            />
          </div>
          <div className="form-group mt-2">
            <textarea
              className="shadow-sm text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="Extra Instructions"
              name="extraInstruction"
              value={assignmentData?.extraInstruction || ""}
              onChange={handleChange}
            ></textarea>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          className="btn btn-secondary signupBtn assBtn h-10 p-2 shadow text-xs text-center text-[#9ca3af] mt-1 d-flex align-items-center"
          onClick={handleClose}
        >
          <i className="fas fa-times fa-xs mr-2"></i>
          Close
        </Button>
        <Button
          variant="primary"
          className="btn btn-secondary signupBtn assBtn h-10 p-2 shadow text-xs text-center text-[#9ca3af] mt-1 d-flex align-items-center"
          onClick={handleSubmit}
        >
          {loading ? (
            <>
              <i className="fas fa-spinner fa-spin fa-xs mr-1"></i>
              Updating...
            </>
          ) : (
            <>
              <i className="fas fa-save fa-xs mr-2"></i>
              Update
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
