import React from "react";

export default function Features() {
  return (
    <div>
      <section class="text-gray-900  bg-gray-200 border-gray-100 shadow  xl:p-8  ">
        <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div class="mb-5">
            <p class="text-gray-500 sm:text-xl text-center ">FEATURES</p>
            <h2 class="mb-4 text-4xl text-center tracking-tight font-extrabold text-[#719fa9]  ">
              Our Key Features
            </h2>
          </div>
          <br />

          <div class="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
            <div className="features bg-gray-100 rounded-md  p-4">
              <div className="flex justify-center items-center mb-4 rounded-full bg-primary-100">
                <img
                  src="../../../../Mathematics-amico.png"
                  alt="mockup"
                  className="img-small"
                />
              </div>
              <h3 class="mb-2 text-2xl text-[#719fa9] font-bold ">
                Assignment Assistance
              </h3>
              <p class="text-gray-500 text-lg font-normal lg:text-xl">
                Receive tailored guidance and support for your academic
                assignments from experienced professionals.
              </p>
            </div>
            <div className="features bg-gray-100 rounded-md p-4">
              <div className="flex justify-center items-center mb-4 w-15 h-15 rounded-full bg-primary-100">
                <img
                  src="../../../../Online learning-amico.png"
                  alt="mockup"
                  className="img-small"
                />
              </div>
              <h3 className="mb-2 text-2xl text-[#719fa9] font-bold">
                Online Tutoring | In person
              </h3>
              <p className="text-gray-500 text-lg font-normal lg:text-xl">
                Access personalized tutoring sessions to enhance your
                understanding and performance in challenging subjects
              </p>
            </div>

            <div className="features bg-gray-100  rounded-md  p-4">
              <div className="flex justify-center items-center mb-4 w-15 h-15 rounded-full bg-primary-100">
                <img
                  src="../../../../Research paper-amico.png"
                  alt="mockup"
                  className="img-small"
                />
              </div>
              <h3 class="mb-2 text-2xl text-[#719fa9] font-bold ">
                Research Assistance
              </h3>
              <p class="text-gray-500 text-lg font-normal lg:text-xl">
                Get assistance with research projects, including topic
                selection, data analysis, and literature reviews, to ensure
                thorough and accurate academic work.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
