import React from "react";
import { Link } from "react-router-dom";

export default function HowItWorks() {
  return (
    <section className="bg-gray-100 pb-5 hidden sm:block">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div class="mb-5">
          <p class="text-gray-500 sm:text-xl text-center "> HOW IT WORKS</p>
          <h2 class="mb-4 text-4xl text-center tracking-tight font-extrabold text-[#719fa9]  ">
            Begin Your Journey in 3 Simple Steps
          </h2>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="road-map-main">
              <div className="road-map-wrapper ">
                <div className="road-map-circle features">
                  <span className="road-map-circle-text d-flex align-items-center justify-content-center">
                    Step 1
                  </span>
                </div>
                <div className="road-map-card bg-gray-200">
                  <h4 className="card-head tracking-wide text-[#719fa9]">
                    <i className="fas fa-user-plus mr-2"></i>
                    Sign Up
                  </h4>
                  <p className="card-text tracking-wide text-gray-500 text-lg font-normal lg:text-l">
                    Signing up is easy and straightforward. Simply provide your
                    full name, phone number, email, and password to create your
                    account. After signing up, you’ll receive a verification
                    email. Verify your email to proceed to the next step.
                  </p>
                </div>
              </div>
              <div className="road-map-wrapper ">
                <div className="road-map-circle features">
                  <span className="road-map-circle-text d-flex align-items-center justify-content-center">
                    Step 2
                  </span>
                </div>
                <div className="road-map-card bg-gray-200">
                  <h4 className="card-head tracking-wide text-[#719fa9]">
                    <i className="fas fa-envelope-open-text mr-2"></i>
                    Verify Your Email
                  </h4>
                  <p className="card-text tracking-wide text-gray-500 text-lg font-normal lg:text-l">
                    Once you've signed up, check your inbox for the verification
                    email. Click on the verification link to confirm your email
                    address. This step is crucial to ensure the security and
                    integrity of your account.
                  </p>
                </div>
              </div>
              <div className="road-map-wrapper">
                <div className="road-map-circle features">
                  <span className="road-map-circle-text d-flex align-items-center justify-content-center">
                    Step 3
                  </span>
                </div>
                <div className="road-map-card bg-gray-200">
                  <h4 className="card-head tracking-wide text-[#719fa9]">
                    <i className="fas fa-sign-in-alt mr-2"></i>
                    Access Our Services
                  </h4>
                  <p className="card-text tracking-wide text-gray-500 text-lg font-normal lg:text-l">
                    After verifying your email, log in to your account to
                    explore all the resources Edumate has to offer. You can
                    access pre-recorded videos, take quizzes, and request
                    assignment help. Our platform is designed to provide you
                    with the tools and support you need to succeed in your
                    academic journey.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center mt-5">
            <Link
              to="/signup"
              className="btn text-white tracking-wide bg-[#719fa9] hover:bg-[#5e8995] py-2"
            >
              <i className="fas fa-arrow-right mr-2"></i>
              Get Started
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
