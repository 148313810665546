import React, { useState } from "react";
import axios from "axios";

export default function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("http://localhost:5000/send", formData)
      .then((response) => {
        alert("Message sent successfully!");
        setFormData({ name: "", email: "", subject: "", message: "" });
      })
      .catch((error) => {
        alert("Failed to send message. Please try again later.");
      });
  };

  return (
    <div className="bg-gray-200 common-margin">
      <div className="container">
        <div className="mx-auto max-w-screen-md mb-5 lg:mb-12">
          <div className="mx-auto max-w-screen-md text-center mb-5 lg:mb-12">
            <p className="text-gray-500 sm:text-xl text-center">CONTACT</p>
            <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-[#719fa9]">
              Get In Touch
            </h2>
            <p className="mb-5 font-light text-gray-500 sm:text-xl">
              Need help with your academic assignments? At Edumate, we provide
              expert assistance to ensure your projects are completed with the
              highest quality and on time. Whether it's research papers, essays,
              or problem sets, our team is here to support your academic
              journey. Reach out to us today and experience reliable,
              personalized academic help that sets you up for success.
            </p>
          </div>
          <div className="features rounded-lg bg-gray-100 p-6 md:p-12 flex flex-col md:flex-row w-full max-w-5xl">
            <div className="bg-sidebar text-white p-6 rounded-lg md:w-1/3 mb-6 md:mb-0 md:mr-6">
              <h2 className="text-lg font-semibold mb-4">
                Contact Information
              </h2>
              <p className="mb-4">
                Access expert assistance for your academic assignments, ensuring
                quality and timely completion.
              </p>
              <ul>
                <li className="mb-2">
                  <i className="fas fa-phone-alt mr-1"></i> +27 602189451
                </li>
                <li className="mb-2">
                  <i className="fas fa-phone-alt mr-1"></i> +27 699067875
                </li>
                <li className="mb-2">
                  <i className="fas fa-envelope mr-1"></i> Edumate@gmail.com
                </li>
              </ul>
            </div>
            <div className="flex-1">
              <form className="space-y-4" onSubmit={handleSubmit}>
                <div className="flex flex-col md:flex-row md:space-x-4">
                  <div className="flex-1">
                    <label htmlFor="name" className="block mb-1 text-textcolor">
                      Your Name
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="w-full p-2 text-gray-700 border rounded-md"
                      placeholder="Edumate"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="flex-1">
                    <label
                      htmlFor="email"
                      className="block mb-1 text-textcolor"
                    >
                      Your Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      className="w-full p-2 text-gray-700 border rounded-md"
                      placeholder="Edumateza@gmail.com"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="subject"
                    className="block mb-1 text-textcolor"
                  >
                    Your Subject
                  </label>
                  <input
                    type="text"
                    id="subject"
                    className="w-full p-2 text-gray-700 border rounded-md"
                    placeholder="Subject"
                    value={formData.subject}
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <label
                    htmlFor="message"
                    className="block mb-1 text-textcolor"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    className="w-full p-2 text-gray-700 border rounded-md"
                    rows="4"
                    placeholder="Your message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                </div>
                <button
                  type="submit"
                  className="bg-sidebar text-white py-2 px-4 rounded-md"
                >
                  <i className="fas fa-paper-plane mr-2"></i> Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
