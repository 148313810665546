import React from "react";

export default function Header() {
  return (
    <div>
      <section class="bg-gray-100 features ">
        <div class="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
          <a
            href="#"
            class="inline-flex justify-between bg-gray-200 items-center py-1 px-1 pr-4 mb-7 text-sm text-gray-700 bg-gray-100 rounded-full  hover:bg-gray-100 "
            role="alert"
          >
            <span class="text-sm font-medium ml-2 p-2">
              We come to you in person
            </span>
            <svg
              class="ml-2 w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </a>
          <h1 class="mb-4 text-3xl font-extrabold tracking-tight leading-none text-[#719fa9] md:text-5xl lg:text-6xl ">
            Welcome to Our Tutoring Services
          </h1>
          <p class="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 ">
            We specialize in providing personalized assistance to students in
            various subjects. Whether you need help with assignments, projects,
            or research, our team of experienced tutors is here to support you
            every step of the way. With both online and in-person tutoring
            options available, we make learning convenient and effective for
            you. Let us help you achieve your academic goals.
          </p>
        </div>
      </section>
    </div>
  );
}
