import React from "react";
import { Modal, Button } from "react-bootstrap";

const UserModal = ({ show, handleClose, user }) => {
  return (
    <Modal show={show} size="lg" onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>User Details</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-backgroundMain">
        <div className="flex">
          <div className="bg-white p-6 rounded-lg shadow-md w-1/3 mr-4 flex flex-col items-center justify-center text-center">
            <img
              src="../../logo.PNG"
              alt="Avatar"
              className="border p-1 w-28 h-28 rounded-full mb-4"
            />
            <p className="text-lg font-extrabold text-blue-600"> {user.name}</p>
          </div>

          <div className="bg-white p-6 rounded-lg shadow-md w-2/3">
            <p className="text-sm mb-4">
              Get your PC running up to 89% faster by removing outdated files
              and optimizing your system. Boost your internet speed in just a
              few clicks.
            </p>
            <h2 className="text-lg font-semibold mb-4 text-blue-600">
              Scan Results
            </h2>
            <div className="space-y-2">
              <div className="flex justify-between items-center">
                <span>Email address :</span>
                {user.email}
                <button className="text-zinc-500">▼</button>
              </div>
              <hr />
              <div className="flex justify-between">
                <span>Phone Number :</span>
                {user.phoneNumber}
                <button className="text-zinc-500">▼</button>
              </div>
              <hr />
              <div className="flex justify-between items-center">
                <span>Windows junk files</span>

                <button className="text-zinc-500">▼</button>
              </div>
              <hr />
              <div className="flex justify-between items-center">
                <span>Registry issues</span>

                <button className="text-zinc-500">▼</button>
              </div>
              <hr />
            </div>
            <button className="bg-blue-600 text-white py-2 px-4 rounded-full mt-4">
              Optimize your PC
            </button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          className="btn signupBtn btn-sm assBtn h-7 p-2 mt-3 mr-2 shadow text-xs text-center text-[#9ca3af] btn-sm mt-1 d-flex align-items-center"
          onClick={handleClose}
        >
          <i className="fas fa-times mr-2"></i> Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserModal;
