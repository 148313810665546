import React, { useState, useEffect } from "react";
import AdminHeader from "../../components/common/header/AdminHeader";
import { Modal, Button } from "react-bootstrap";
import {
  saveAppointmentData,
  getAppointmentByUid,
} from "../../firebase/Functions";
import { toast } from "react-toastify";
import { useUser } from "../../context/AuthContext";

export default function Appointments() {
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState();
  const [currentMonth, setCurrentMonth] = useState("");
  const [currentDate, setCurrentDate] = useState("");
  const [appointments, setAppointments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [appointmentsPerPage] = useState(2);
  const [showCreateEventModal, setShowCreateEventModal] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const { extractUid, getUserByUid, getAllStudents } = useUser();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userJSON = localStorage.getItem("user");
        if (userJSON) {
          const userData = JSON.parse(userJSON);
          const currentUser = await getUserByUid(extractUid(userData));
          setUser(currentUser);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUser();
  }, [getUserByUid, extractUid]);

  const fetchUsers = async () => {
    try {
      const response = await getAllStudents();
      setUsers(response);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const initialFormData = {
    appointmentTitle: "",
    startTime: "",
    endTime: "",
    duration: "",
    venue: "",
    date: "",
    person: "",
  };

  const initialErrors = {
    appointmentTitle: "",
    startTime: "",
    endTime: "",
    duration: "",
    venue: "",
    date: "",
    person: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [errors, setErrors] = useState(initialErrors);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getCurrentMonth = () => {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const currentDate = new Date();
      return months[currentDate.getMonth()];
    };

    const getCurrentDate = () => {
      const currentDate = new Date();
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return currentDate.toLocaleDateString("en-US", options);
    };

    const fetchAppointments = async () => {
      if (user && user.uid) {
        const data = await getAppointmentByUid(user.uid);
        setAppointments(data);
      }
    };

    setCurrentMonth(getCurrentMonth());
    setCurrentDate(getCurrentDate());
    fetchAppointments();
  }, [user]);

  const indexOfLastAppointment = currentPage * appointmentsPerPage;
  const indexOfFirstAppointment = indexOfLastAppointment - appointmentsPerPage;
  const currentAppointments = appointments.slice(
    indexOfFirstAppointment,
    indexOfLastAppointment
  );

  const handleCreateEvent = () => setShowCreateEventModal(true);

  const handleAppointmentClick = (appointment) =>
    setSelectedAppointment(appointment);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let hasError = false;
    const newErrors = { ...initialErrors };

    if (!formData.appointmentTitle) {
      newErrors.appointmentTitle = "Appointment title is required";
      hasError = true;
    }

    if (!formData.startTime) {
      newErrors.startTime = "Start time is required";
      hasError = true;
    }

    if (!formData.endTime) {
      newErrors.endTime = "End time is required";
      hasError = true;
    }

    if (!formData.duration) {
      newErrors.duration = "Duration is required";
      hasError = true;
    }

    if (!formData.venue) {
      newErrors.venue = "Venue is required";
      hasError = true;
    }

    if (!formData.date) {
      newErrors.date = "Date is required";
      hasError = true;
    }

    if (!formData.person) {
      newErrors.person = "Person is required";
      hasError = true;
    }

    if (hasError) {
      setErrors(newErrors);
      return;
    }

    try {
      setLoading(true);
      await saveAppointmentData(user.uid, formData);
      toast.success("Appointment data saved successfully!");
      setFormData(initialFormData);
      setShowCreateEventModal(false);
    } catch (error) {
      toast.error("Failed to save appointment data. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const getRandomColorClass = () => {
    const colors = [
      "bg-yellow-500",
      "bg-indigo-500",
      "bg-purple-500",
      "bg-pink-500",
    ];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  };

  const randomColorClass = getRandomColorClass();

  return (
    <div className="md:container">
      <AdminHeader
        headerText="Appointments"
        descriptionText="View and manage all appointment details. Keep track of appointment statuses, and ensure accurate scheduling records"
      />
      <div className="card p-2">
        <div className="card-header flex justify-between items-center mb-4">
          <h1 className="text-2xl text-textcolor font-bold">{currentMonth}</h1>
          <button
            className="bg-sidebar text-xs text-white px-4 py-2 rounded"
            onClick={handleCreateEvent}
          >
            <i className="fas fa-calendar-plus fa-xs mr-2"></i> Create Event
          </button>
        </div>
        <p className="text-zinc-500 mb-3 pl-1">{`Today is ${currentDate}`}</p>
        <div className="space-y-4">
          {currentAppointments.map((appointment, index) => (
            <div
              key={index}
              className={`p-4 rounded-lg shadow ${randomColorClass}`}
              style={{ cursor: "pointer" }}
              onClick={() => handleAppointmentClick(appointment)}
            >
              <div className="flex justify-between items-center">
                <h2 className="font-extrabold mb-2 leading-6">
                  <i className="fas fa-calendar-check mr-2"></i>
                  {appointment.appointmentTitle}
                </h2>
                <span className="text-gray-300">
                  <i className="fas fa-clock mr-2"></i> {appointment.duration}
                </span>
              </div>
              <p className="text-gray-300">
                <i className="far fa-calendar-alt mr-1"></i> Start time:{" "}
                {appointment.startTime}
              </p>
            </div>
          ))}
        </div>
        <div className="mt-3 card-footer p-2">
          <ul className="flex space-x-2">
            {Array.from(
              { length: Math.ceil(appointments.length / appointmentsPerPage) },
              (_, i) => (
                <li key={i}>
                  <button
                    className={`px-3 py-1 rounded ${
                      currentPage === i + 1
                        ? "bg-gray-500"
                        : "bg-gray-400 hover:bg-gray-300"
                    }`}
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </button>
                </li>
              )
            )}
          </ul>
        </div>
      </div>

      {/* Create Event Modal */}
      <Modal
        show={showCreateEventModal}
        size="lg"
        onHide={() => setShowCreateEventModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="font-semibold">Create Event</Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-backgroundMain">
          <div className="mx-auto max-w-screen-md">
            <form className="mb-2 p-2" onSubmit={handleSubmit}>
              {error && (
                <div
                  className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50"
                  role="alert"
                >
                  {error}
                </div>
              )}
              <div className="mb-4">
                <label
                  htmlFor="appointmentTitle"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Appointment Title
                </label>
                <input
                  type="text"
                  id="appointmentTitle"
                  name="appointmentTitle"
                  value={formData.appointmentTitle}
                  onChange={handleChange}
                  className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.appointmentTitle ? "border-red-500" : ""
                  }`}
                  placeholder="Enter appointment title"
                />
                {errors.appointmentTitle && (
                  <p className="text-red-500 mt-2 text-xs italic">
                    {errors.appointmentTitle}
                  </p>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="person"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Person
                </label>
                <select
                  id="person"
                  name="person"
                  value={formData.person}
                  onChange={handleChange}
                  className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.person ? "border-red-500" : ""
                  }`}
                >
                  <option value="">Select person</option>
                  {users &&
                    users.length > 0 &&
                    users.map((use) => (
                      <option key={use.uid} value={use.uid}>
                        {use.name}
                      </option>
                    ))}
                </select>
                {errors.person && (
                  <p className="text-red-500 mt-2 text-xs italic">
                    {errors.person}
                  </p>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="startTime"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Date
                </label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.startTime ? "border-red-500" : ""
                  }`}
                  placeholder="Enter start time"
                />
                {errors.date && (
                  <p className="text-red-500 mt-2 text-xs italic">
                    {errors.date}
                  </p>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="startTime"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Start Time
                </label>
                <input
                  type="time"
                  id="startTime"
                  name="startTime"
                  value={formData.startTime}
                  onChange={handleChange}
                  className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.startTime ? "border-red-500" : ""
                  }`}
                  placeholder="Enter start time"
                />
                {errors.startTime && (
                  <p className="text-red-500 mt-2 text-xs italic">
                    {errors.startTime}
                  </p>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="endTime"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  End Time
                </label>
                <input
                  type="time"
                  id="endTime"
                  name="endTime"
                  value={formData.endTime}
                  onChange={handleChange}
                  className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.endTime ? "border-red-500" : ""
                  }`}
                  placeholder="Enter end time"
                />
                {errors.endTime && (
                  <p className="text-red-500 mt-2 text-xs italic">
                    {errors.endTime}
                  </p>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="duration"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Duration
                </label>
                <input
                  type="text"
                  id="duration"
                  name="duration"
                  value={formData.duration}
                  onChange={handleChange}
                  className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.duration ? "border-red-500" : ""
                  }`}
                  placeholder="Enter duration"
                />
                {errors.duration && (
                  <p className="text-red-500 mt-2 text-xs italic">
                    {errors.duration}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="venue"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Venue
                </label>
                <input
                  type="text"
                  id="venue"
                  name="venue"
                  value={formData.venue}
                  onChange={handleChange}
                  className={`shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 ${
                    errors.venue ? "border-red-500" : ""
                  }`}
                  placeholder="Enter venue"
                />
                {errors.venue && (
                  <p className="text-red-500 mt-2 text-xs italic">
                    {errors.venue}
                  </p>
                )}
              </div>
              <Button
                variant="primary"
                type="submit"
                className="btn signupBtn btn-sm assBtn h-7 p-2 mt-3 shadow text-xs text-center text-[#9ca3af] btn-sm mt-1 d-flex align-items-center"
                disabled={loading}
              >
                {loading ? (
                  <>
                    <i className="fas fa-spinner fa-spin fa-xs mr-2"></i>
                    Saving...
                  </>
                ) : (
                  <>
                    <i className="fas fa-save fa-xs mr-2"></i> Save Changes
                  </>
                )}
              </Button>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn signupBtn btn-sm assBtn h-7 p-2 mt-3 shadow text-xs text-center text-[#9ca3af] btn-sm mt-1 d-flex align-items-center"
            onClick={() => setShowCreateEventModal(false)}
          >
            <i className="fas fa-times fa-xs mr-2"></i> Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={selectedAppointment !== null}
        size="lg"
        onHide={() => setSelectedAppointment(null)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="font-extrabold">
            {selectedAppointment?.appointmentTitle}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-backgroundMain p-6  shadow-md">
          <div className="space-y-6">
            <div className="flex items-center space-x-4">
              <i className="far fa-calendar-alt text-purple-500 icon-large"></i>
              <div className="text-textcolor">
                <p className="text-lg font-semibold">Date</p>
                <p className="text-xs">{selectedAppointment?.date}</p>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <i className="far fa-clock text-purple-500 icon-large"></i>
              <div className="text-textcolor">
                <p className="text-lg font-semibold">Time</p>
                <p className="text-xs">
                  {selectedAppointment?.startTime} -{" "}
                  {selectedAppointment?.endTime}
                </p>
              </div>
            </div>

            <div className="flex items-center space-x-4">
              <i className="far fa-user text-purple-500 icon-large"></i>
              <div className="text-textcolor">
                <p className="text-lg font-semibold">with</p>
                <p className="text-xs">{selectedAppointment?.person}</p>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <i className="fa fa-map-marker-alt text-purple-500 icon-large"></i>
              <div className="text-textcolor">
                <p className="text-lg font-semibold">Venue</p>
                <p className="text-xs">{selectedAppointment?.venue}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            className="btn signupBtn btn-sm assBtn h-7 p-2 mt-3 shadow text-xs text-center text-[#9ca3af] btn-sm mt-1 d-flex align-items-center border border-green-600"
          >
            <i className="fas fa-check fa-xs mr-2"></i> Approve
          </Button>
          <Button
            variant="danger"
            className="btn signupBtn btn-sm assBtn h-7 p-2 mt-3 shadow text-xs text-center text-[#9ca3af] btn-sm mt-1 d-flex align-items-center border border-red-600"
          >
            <i className="fas fa-times-circle fa-xs mr-2"></i> Decline
          </Button>
          <Button
            variant="secondary"
            className="btn signupBtn btn-sm assBtn h-7 p-2 mt-3 shadow text-xs text-center text-[#9ca3af] btn-sm mt-1 d-flex align-items-center border border-gray-600"
            onClick={() => setSelectedAppointment(null)}
          >
            <i className="fas fa-times fa-xs mr-2"></i> Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
