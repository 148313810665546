import React, { useState, useEffect } from "react";
import { Button, Form, Spinner, Card, Alert } from "react-bootstrap";
import { getQuizById } from "../../../firebase/Quiz";

export default function QuizView({ quizId }) {
  const [quiz, setQuiz] = useState(null);
  const [loading, setLoading] = useState(true);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [score, setScore] = useState(0);
  const [graded, setGraded] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [correctAnswer, setCorrectAnswer] = useState(null);

  useEffect(() => {
    const fetchQuiz = async () => {
      setLoading(true);
      const fetchedQuiz = await getQuizById(quizId);
      setQuiz(fetchedQuiz);
      setLoading(false);
    };

    fetchQuiz();
  }, [quizId]);

  useEffect(() => {
    calculateScore();
  }, [userAnswers, quiz]);

  const handleAnswerChange = (questionIndex, answer) => {
    if (quiz.quiz[questionIndex].questionType === "multipleChoice") {
      // Find the index of the selected answer
      const selectedAnswerIndex =
        quiz.quiz[questionIndex].answers.indexOf(answer);
      setUserAnswers((prevAnswers) => ({
        ...prevAnswers,
        [questionIndex]: selectedAnswerIndex,
      }));
    } else {
      // For other question types, store the answer directly
      setUserAnswers((prevAnswers) => ({
        ...prevAnswers,
        [questionIndex]: answer,
      }));
    }

    setSelectedAnswer(answer);
    const correctAnswerIndex = parseInt(
      quiz.quiz[questionIndex].correctAnswer[0]
    );
    setCorrectAnswer(correctAnswerIndex);
  };

  const calculateScore = () => {
    if (!quiz) return;

    let newScore = 0;
    quiz.quiz.forEach((question, index) => {
      const userAnswer = userAnswers[index];
      const correctAnswerIndex = question.correctAnswer[0];

      // For multipleChoice and fillAnswer questions
      if (
        question.questionType === "multipleChoice" ||
        question.questionType === "fillAnswer"
      ) {
        if (userAnswer === correctAnswerIndex) {
          newScore += 1;
        }
      }

      // For multipleAnswer questions
      if (question.questionType === "multipleAnswer") {
        const correctAnswers = question.correctAnswer.map((ans) =>
          parseInt(ans)
        );
        if (
          userAnswer &&
          correctAnswers.every((ans) => userAnswer.includes(ans))
        ) {
          newScore += 1;
        }
      }
    });

    setScore(newScore);
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "40vh" }}
      >
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  if (!quiz) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <p>Failed to load quiz data. Please try again later.</p>
      </div>
    );
  }

  const question = quiz.quiz[currentQuestionIndex];

  const handleNext = () => {
    if (currentQuestionIndex < quiz.quiz.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedAnswer(null);
      setCorrectAnswer(null);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setSelectedAnswer(null);
      setCorrectAnswer(null);
    }
  };

  const handleGradeQuiz = () => {
    setGraded(true);
  };

  return (
    <div className="d-flex justify-content-center align-items-center flex-column">
      <Card className="p-4 shadow-sm">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <span className="text-muted">
            Question {currentQuestionIndex + 1} of {quiz.quiz.length}
          </span>
          <span className="font-weight-bold text-textcolor">
            Current score: {score}
          </span>
        </Card.Header>
        <Card.Body>
          <Card.Title className="mb-4 text-textcolor">
            Question {currentQuestionIndex + 1}
          </Card.Title>
          <Card.Text
            className="text-textcolor"
            dangerouslySetInnerHTML={{ __html: question.question }}
          />

          {question.questionType === "multipleAnswer" && (
            <Form className="mt-3 mb-3 text-textcolor">
              {question.answers.map((answer, idx) => (
                <Form.Check
                  key={idx}
                  id={`checkbox${currentQuestionIndex + 1}_${idx}`}
                  name={`checkbox${currentQuestionIndex + 1}`}
                  type="checkbox"
                  label={<span dangerouslySetInnerHTML={{ __html: answer }} />}
                  checked={
                    userAnswers[currentQuestionIndex]?.includes(answer) || false
                  }
                  onChange={(e) => {
                    const currentAnswers =
                      userAnswers[currentQuestionIndex] || [];
                    const updatedAnswers = e.target.checked
                      ? [...currentAnswers, answer]
                      : currentAnswers.filter((a) => a !== answer);
                    handleAnswerChange(currentQuestionIndex, updatedAnswers);
                  }}
                  disabled={graded}
                />
              ))}
            </Form>
          )}

          {question.questionType === "multipleChoice" && (
            <Form className="mt-3 mb-3 text-textcolor">
              {question.answers.map((answer, idx) => (
                <Form.Check
                  key={idx}
                  id={`radio${currentQuestionIndex + 1}_${idx}`}
                  name={`radio${currentQuestionIndex + 1}`}
                  type="radio"
                  label={<span dangerouslySetInnerHTML={{ __html: answer }} />}
                  checked={userAnswers[currentQuestionIndex] === answer}
                  onChange={() =>
                    handleAnswerChange(currentQuestionIndex, answer)
                  }
                  disabled={graded} // Disable radio buttons after grading
                />
              ))}
            </Form>
          )}

          {question.questionType === "fillAnswer" && (
            <Form.Control
              type="text"
              placeholder="Enter your answer"
              className="mt-3 mb-3"
              value={userAnswers[currentQuestionIndex] || ""}
              onChange={(e) =>
                handleAnswerChange(currentQuestionIndex, e.target.value)
              }
              disabled={graded} // Disable text input after grading
            />
          )}
          {graded && (
            <div className="mt-3 bg-gray-100 p-3 mt-4 rounded-lg shadow-inner">
              <h5 className="text-textcolor font-semibold">
                <i className="fas fa-check-circle mr-2 text-green-600"></i>
                Correct Answer:
              </h5>
              {question.questionType === "fillAnswer" ? (
                <p className="mt-2 text-green-600 font-medium">
                  {question.correctAnswer[0]}
                </p>
              ) : (
                <p
                  className="mt-2 text-green-600 font-medium"
                  dangerouslySetInnerHTML={{
                    __html: question.answers[question.correctAnswer[0]],
                  }}
                ></p>
              )}
            </div>
          )}

          {graded && (
            <div className="mt-3 bg-gray-100 p-3 mt-4 rounded-lg shadow-inner">
              <h5 className="text-textcolor font-semibold">
                <i className="fas fa-info-circle mr-2 text-blue-600"></i>
                Explanation:
              </h5>
              <Card.Text
                className="mt-2 text-blue-600 font-medium"
                dangerouslySetInnerHTML={{ __html: question.explanation }}
              />
            </div>
          )}
        </Card.Body>
        <Card.Footer className="d-flex justify-content-between align-items-center">
          <Button
            variant="secondary"
            className="text-xs"
            onClick={handlePrevious}
            disabled={currentQuestionIndex === 0}
          >
            <i className="fas fa-arrow-left fa-xs mr-1"></i> Back
          </Button>
          {currentQuestionIndex === quiz.quiz.length - 1 ? (
            <Button
              variant="success"
              className="text-xs"
              onClick={handleGradeQuiz}
            >
              <i className="fas fa-check mr-1 fa-xs"></i> Grade Quiz
            </Button>
          ) : (
            <Button variant="primary" className="text-xs" onClick={handleNext}>
              Next <i className="fas fa-arrow-right ml-1 fa-xs"></i>
            </Button>
          )}
        </Card.Footer>
      </Card>
      {graded && (
        <Alert variant="info" className="mt-4">
          Your final score is {score} out of {quiz.quiz.length}.
        </Alert>
      )}
    </div>
  );
}
