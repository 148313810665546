import React, { useState } from "react";
import { useUser } from "../../context/AuthContext";
import Hero from "../../components/common/hero/Hero";

export default function Signup() {
  const [errors, setErrors] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    password: "",
    repeatPassword: "",
    agreedToTerms: false,
  });

  const { signup } = useUser();

  // Form validation
  const validateForm = () => {
    let formErrors = {};
    if (!formData.name.trim()) {
      formErrors.name = "First name is required";
    }
    if (!formData.email.trim()) {
      formErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Invalid email address";
    }
    if (!formData.phoneNumber.trim()) {
      formErrors.phoneNumber = "Phone number is required";
    }
    if (!formData.password.trim()) {
      formErrors.password = "Password is required";
    } else if (formData.password.length < 6) {
      formErrors.password = "Password must be at least 6 characters long";
    }
    if (!formData.repeatPassword.trim()) {
      formErrors.repeatPassword = "Confirm password is required";
    } else if (formData.repeatPassword !== formData.password) {
      formErrors.repeatPassword = "Passwords do not match";
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    try {
      setLoading(true);
      await signup(formData);
      setLoading(false);
      setFormData({
        name: "",
        email: "",
        phoneNumber: "",
        password: "",
        repeatPassword: "",
        agreedToTerms: false,
      });
      window.location = "/login";
    } catch (error) {
      let errorMessage = "An error occurred during signup.";
      switch (error.code) {
        case "auth/email-already-in-use":
          errorMessage = "Email address is already in use.";
          break;
        case "auth/invalid-email":
          errorMessage = "Invalid email address.";
          break;
        case "auth/weak-password":
          errorMessage = "Password is too weak.";
          break;
        default:
          errorMessage = error.message;
      }
      setFormData({
        name: "",
        email: "",
        phoneNumber: "",
        password: "",
        repeatPassword: "",
        agreedToTerms: false,
      });
      setLoading(false);
      setError(errorMessage);
    }
  };

  return (
    <>
      <Hero
        heading="Sign Up Now"
        paragraph=" Ready to take control of your academic success? Sign up with
              Edumate today and unlock a world of expert assistance for your
              assignments."
        buttonText="Enquire"
        backgroundImage=""
      />
      <div className="bg-gray-200 common-margin -mt-5">
        <div className="container">
          <div className="features p-3 mx-auto max-w-screen-md">
            <form className="mb-2  p-2" onSubmit={handleSubmit}>
              {error && (
                <div
                  class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 "
                  role="alert"
                >
                  {error}
                </div>
              )}
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Name and surname
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="Edumate"
                />
                {errors.name && (
                  <p className="text-red-500 mt-2 text-xs italic">
                    {errors.name}
                  </p>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Your email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="edumate@gmail.com"
                />
                {errors.email && (
                  <p className="text-red-500 mt-2 text-xs italic">
                    {errors.email}
                  </p>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="phoneNumber"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Phone number
                </label>
                <input
                  type="tel"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="+27 602189458"
                />
                {errors.phoneNumber && (
                  <p className="text-red-500 mt-2 text-xs italic">
                    {errors.phoneNumber}
                  </p>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Your password
                </label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="**********"
                />
                {errors.password && (
                  <p className="text-red-500 mt-2 text-xs italic">
                    {errors.password}
                  </p>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="repeat-password"
                  className="block mb-2 text-sm font-medium text-gray-900"
                >
                  Repeat password
                </label>
                <input
                  type="password"
                  id="repeat-password"
                  name="repeatPassword"
                  value={formData.repeatPassword}
                  onChange={handleChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                  placeholder="**********"
                />
                {errors.repeatPassword && (
                  <p className="text-red-500 mt-2 text-xs italic">
                    {errors.repeatPassword}
                  </p>
                )}
              </div>
              <div className="flex items-start mb-4">
                <div className="flex items-center h-5">
                  <input
                    id="link-checkbox"
                    type="checkbox"
                    value=""
                    class="form-check-input w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2"
                  ></input>
                </div>
                <label
                  htmlFor="terms"
                  className="ms-2 mt-1 text-sm font-medium text-gray-900"
                >
                  I agree with the{" "}
                  <a href="#" className="text-blue-600 hover:underline ">
                    terms and conditions
                  </a>
                </label>
              </div>
              <button
                type="submit"
                className="text-white signupBtn  hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium text-sm w-full sm:w-auto px-5 py-2 text-center"
              >
                {loading ? (
                  <i className="fas fa-spinner fa-spin mr-1"></i>
                ) : (
                  <i className="fas fa-user-plus mr-2"></i>
                )}
                {loading ? "Registering..." : "Register"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
