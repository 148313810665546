import React from "react";
import Hero from "./partials/Hero";
import Features from "./partials/Features";
import HowItWorks from "./partials/HowItWorks";
import Testimonals from "./partials/Testimonals";
import Faq from "./partials/Faq";
import Header from "./partials/Header";
import Contact from "../contact/Contact";

import Dashboard from "../dashboard/Dashboard";

export default function Home() {
  return (
    <div>
      <Hero />
      <Header />
      <Features />
      <HowItWorks />
      <Faq />
      <Testimonals />
    </div>
  );
}
