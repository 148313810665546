import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useUser } from "../../../../context/AuthContext";
import { initializePaystackPayment } from "../../../../api/payment_processing";
import { generatePaymentReference } from "../../../../utils/utils";
import { saveTransactionAndUpdateAssignment } from "../../../../firebase/Functions";

export default function AssignmentData({
  assignment,
  handleUpdate,
  deleteAssignmentAndFetchData,
}) {
  const [userInfo, setUserInfo] = useState(null);
  const [paid, setPaid] = useState(assignment.paid);
  const [isLoading, setIsLoading] = useState(true);

  const handleDeleteClick = (assignment) => {
    deleteAssignmentAndFetchData(assignment);
  };

  const { extractUid, getUserByUid } = useUser();

  const onSuccess = (response) => {
    const reference = response.reference;
    try {
      const transactionDocRef = saveTransactionAndUpdateAssignment(
        userInfo.email,
        assignment.module,
        assignment.price,
        reference,
        assignment.id
      );
      console.log(transactionDocRef.id);
      setPaid(true);
    } catch (error) {
      console.error("Error occurred: ", error);
    }
  };

  const onClose = () => {
    alert("");
  };

  const handlePay = async () => {
    if (assignment.price) {
      const amount = assignment.price;
      const email = userInfo.email;
      const reference = await generatePaymentReference();
      try {
        initializePaystackPayment({
          email,
          amount,
          reference,
          onSuccess,
          onClose,
        });
      } catch (error) {
        throw error;
      }
    } else {
      console.log("No price value in the assignment");
    }
  };

  const fetchUser = async () => {
    try {
      const userJSON = localStorage.getItem("user");
      if (userJSON) {
        const userData = JSON.parse(userJSON);
        const currentUser = await getUserByUid(extractUid(userData));
        setUserInfo(currentUser);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, [getUserByUid, extractUid]);

  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (!assignment) {
    return <p>You haven't uploaded any assignments yet.</p>;
  }

  return (
    <div
      className={`border-l-4 mb-4 bg-cardsButtons`}
      style={{ borderColor: paid ? "#4CAF50" : "#FFC107" }}
    >
      <div className="p-4 shadow-lg">
        <div className="flex justify-between items-center mb-2">
          <h3 className="text-lg text-textcolor font-extrabold flex items-center">
            <i className="fas fa-book mr-2"></i>
            <span className="fs-sm">{assignment.module}</span>
          </h3>
          <span
            className={
              paid
                ? "text-sm text-green-600 mt-1"
                : "text-sm text-yellow-500 mt-1"
            }
          >
            {paid ? (
              <>
                Paid <i className="fas fa-check-circle mt-1 ml-1"></i>
              </>
            ) : (
              <>
                Unpaid <i className="fas fa-times-circle mt-1 ml-1"></i>
              </>
            )}
          </span>
        </div>
        <hr className="mb-2 border border-bordercolor bg-bordercolor" />
        <p className="text-textcolor text-xs mb-2 flex items-center">
          <i className="far fa-calendar mr-2 text-textcolor"></i>
          Due date: {assignment.date}
        </p>

        <p className="text-textcolor mb-2 text-xs flex items-center">
          <i className="far fa-money-bill-alt mr-2 text-textcolor"></i>
          Price:{" "}
          {assignment.price ? `R${assignment.price}` : "Awaiting evaluation"}
        </p>
        <p className="text-textcolor text-xs flex items-center">
          <i className="fas fa-clipboard-check mr-2 text-textcolor"></i>
          Answer: {assignment.answer ? "Available" : "Not received"}
        </p>
      </div>
      <div className="flex items-center px-3 py-2 mb-2 space-x-2 justify-between">
        {assignment.price && !paid ? (
          <button
            onClick={handlePay}
            className="btn btn-sm bg-cardsButtons border border-border text-textcolor px-3 py-1 rounded hover:bg-cardsButtons"
          >
            <i className="fas fa-money-bill-alt mr-2"></i>Pay
          </button>
        ) : null}
        {assignment.answer && paid ? (
          <Button
            href={assignment.answerUrl}
            className="btn btn-sm bg-cardsButtons border border-border text-textcolor px-3 py-1 rounded hover:hover:bg-cardsButtons"
          >
            <i className="fas fa-download mr-2"></i>Download
          </Button>
        ) : null}
        <div className="flex space-x-2 ml-auto">
          <button
            onClick={() => handleDeleteClick(assignment)}
            className="btn btn-sm bg-cardsButtons border border-border text-textcolor px-3 py-1 rounded hover:hover:bg-cardsButtons"
          >
            <i className="fas fa-trash mr-2"></i>Delete
          </button>
        </div>
      </div>
    </div>
  );
}
