import React, { useState, useEffect } from "react";
import {
  fetchAllAssignments,
  fetchAssignmentById,
  updatePrice,
} from "../../../firebase/Functions";
import { Modal, Button, Spinner, Form, Pagination } from "react-bootstrap";
import AssignmentDetails from "./AssignmentDetails";
import { toast } from "react-toastify";

export default function AssignmentTable() {
  const [assignments, setAssignments] = useState([]);
  const [filteredAssignments, setFilteredAssignments] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [assignmentId, setAssignmentId] = useState("");
  const [loadingData, setLoadingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(null);
  const [formData, setFormData] = useState({
    price: "",
  });
  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleShowModal = async (assignment) => {
    setAssignmentId(assignment);
    try {
      const result = await fetchAssignmentById(assignment);
      console.log(result);
    } catch (error) {
      console.log(error);
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    fetchAssignments();
  };

  const fetchAssignments = async () => {
    try {
      setLoadingData(true);
      const data = await fetchAllAssignments();
      setAssignments(data);
      setFilteredAssignments(data);
    } catch (error) {
      console.error("Error fetching assignments:", error);
    } finally {
      setLoadingData(false);
    }
  };

  useEffect(() => {
    fetchAssignments();
  }, []);

  useEffect(() => {
    filterAssignments();
  }, [searchTerm, filter, assignments]);

  const filterAssignments = () => {
    let filtered = assignments;

    if (searchTerm) {
      filtered = filtered.filter((assignment) =>
        assignment.module.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (filter === "answered") {
      filtered = filtered.filter((assignment) => assignment.answer);
    } else if (filter === "unanswered") {
      filtered = filtered.filter((assignment) => !assignment.answer);
    } else if (filter === "paid") {
      filtered = filtered.filter((assignment) => assignment.paid);
    } else if (filter === "unpaid") {
      filtered = filtered.filter((assignment) => !assignment.paid);
    }

    setFilteredAssignments(filtered);
    setCurrentPage(1);
  };

  async function handlePrice(event, id) {
    event.preventDefault();

    if (!formData.price) {
      setFormError("Please enter a price.");
      return;
    }

    setLoading(true);

    try {
      const success = await updatePrice(formData, id);
      if (success) {
        toast.success("Price updated successfully");
        fetchAssignments();
        setFormError("");

        setFormData({ price: "" });
      } else {
        console.log("Failed to update price");
      }
    } catch (error) {
      console.error("Error updating price:", error);
    } finally {
      setLoading(false);
    }
  }

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredAssignments.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const formatDate = (dateStr) => {
    try {
      const parsedDate = parse(dateStr, "yyyy-MM-dd", new Date());
      return format(parsedDate, "dd MMMM yyyy");
    } catch (error) {
      console.error("Date parsing/formatting error:", error);
      return dateStr;
    }
  };

  return (
    <div className="projects">
      <div className="projects-inner">
        {loadingData ? (
          <div
            className="flex justify-center items-center"
            style={{ height: "60vh" }}
          >
            <div className="text-center text-gray-300">
              <Spinner
                animation="border"
                className="text-textcolor"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            </div>
          </div>
        ) : currentItems.length > 0 ? (
          <>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <Form.Control
                type="text"
                placeholder="🔍 Search by module..."
                value={searchTerm}
                onChange={handleSearchChange}
                className="w-50  h-8 text-xs"
              />
              <Form.Select
                value={filter}
                onChange={handleFilterChange}
                className="w-auto  h-8 text-xs"
              >
                <option value="all">All</option>
                <option value="answered">Answered</option>
                <option value="unanswered">Unanswered</option>
                <option value="paid">Paid</option>
                <option value="unpaid">Unpaid</option>
              </Form.Select>
            </div>
            <div className="table-responsive">
              <div className="overflow-x-auto">
                <table className="min-w-full bg-white border rounded-lg shadow-md">
                  <thead>
                    <tr className="bg-sidebar text-white">
                      <th className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm font-semibold">
                        Module
                      </th>
                      <th className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm hidden md:table-cell">
                        Due date
                      </th>
                      <th className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm ">
                        Price
                      </th>
                      <th className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm hidden md:table-cell">
                        Solution status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((assignment) => (
                      <tr key={assignment.id} className="border-t">
                        <td className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm">
                          <p
                            onClick={() => handleShowModal(assignment.id)}
                            className="text-gray-700 cursor-pointer"
                          >
                            <i className="fas fa-book mr-2"></i>
                            {assignment.module}
                          </p>
                        </td>
                        <td className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm text-gray-700 hidden md:table-cell">
                          <p>
                            <i className="fas fa-calendar-alt mr-2"></i>
                            {formatDate(assignment.date)}
                          </p>
                        </td>
                        <td className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm text-gray-700">
                          <div>
                            {assignment.price ? (
                              <div className="member-info">
                                <p>
                                  <div>R{assignment.price}</div>
                                </p>
                                <p className="text-green-200">
                                  {assignment.hasOwnProperty("paid") ? (
                                    assignment.paid ? (
                                      <i className="fas fa-check-circle py-1 fa-xs pr-2 text-success">
                                        <span className="ml-1 text-xs">
                                          Paid
                                        </span>
                                      </i>
                                    ) : (
                                      <i className="fas fa-times-circle fa-xs text-danger">
                                        <span className="ml-1 text-xs">
                                          Outstanding payment
                                        </span>
                                      </i>
                                    )
                                  ) : (
                                    <span className="text-xs text-red-500 py-1">
                                      <i className="fas fa-exclamation-circle fa-sm  mr-1"></i>
                                      Outstanding payment
                                    </span>
                                  )}
                                </p>
                              </div>
                            ) : (
                              <>
                                {formError && (
                                  <div
                                    className="p-1 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                                    role="alert"
                                  >
                                    {formError}
                                  </div>
                                )}
                                <form
                                  className="flex items-center"
                                  onSubmit={(event) =>
                                    handlePrice(event, assignment.id)
                                  }
                                >
                                  <input
                                    type="text"
                                    id="price"
                                    aria-describedby="helper-text-explanation"
                                    className="bg-gray-50 w-20 h-6 text-gray-900 text-xs md:text-sm focus:ring-blue-500 focus:border-blue-500 block p-2.5 mr-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                    name="price"
                                    value={formData.price}
                                    onChange={handleChange}
                                    placeholder="R"
                                  />
                                  <button
                                    type="submit"
                                    className="btn btn-info text-xs text-white btn-sm d-flex align-items-center"
                                  >
                                    {loading ? (
                                      <i className="fas fa-spinner fa-spin fa-xs mr-1"></i>
                                    ) : (
                                      <span className="mr-1 font-bold">R</span>
                                    )}
                                    {loading ? "setting..." : "Set price"}
                                  </button>
                                </form>
                              </>
                            )}
                          </div>
                        </td>
                        <td className="py-2 px-2 text-xs md:py-3 md:px-4 md:text-sm text-gray-700 hidden md:table-cell">
                          <div className="member-info">
                            {assignment.answer ? (
                              <span className="text-success">
                                <i className="fas fa-check-circle mr-2"></i>
                                Answer Submitted
                              </span>
                            ) : (
                              <span className="text-warning">
                                <i className="fas fa-exclamation-circle mr-2"></i>
                                No Answer Submitted
                              </span>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <nav aria-label="Page navigation">
              <ul className="pagination mt-3">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <button
                    className="page-link"
                    onClick={() => paginate(currentPage - 1)}
                    aria-label="Previous"
                  >
                    <span aria-hidden="true">&laquo;</span>
                  </button>
                </li>
                {Array.from(
                  {
                    length: Math.ceil(
                      filteredAssignments.length / itemsPerPage
                    ),
                  },
                  (_, index) => (
                    <li
                      key={index + 1}
                      className={`page-item ${
                        index + 1 === currentPage ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => paginate(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  )
                )}
                <li
                  className={`page-item ${
                    currentPage ===
                    Math.ceil(filteredAssignments.length / itemsPerPage)
                      ? "disabled"
                      : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => paginate(currentPage + 1)}
                    aria-label="Next"
                  >
                    <span aria-hidden="true">&raquo;</span>
                  </button>
                </li>
              </ul>
            </nav>
          </>
        ) : (
          <p>No data</p>
        )}
      </div>
      <Modal show={showModal} size="lg" onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title className="font-semibild">
            Assignment Details
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-backgroundMain">
          <AssignmentDetails
            assignmentId={assignmentId}
            handleCloseModal={handleCloseModal}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn signupBtn btn-sm assBtn h-7 p-2 mt-3 shadow text-xs text-center text-[#9ca3af] btn-sm mt-1 d-flex align-items-center"
            onClick={handleCloseModal}
          >
            <i className="fas fa-times fa-xs mr-2"></i>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
