import React, { useState } from "react";

const FAQItem = ({ question, answer, isOpen, onToggle }) => (
  <div className="relative mb-3">
    <h6 className="mb-0">
      <button
        className="relative text-xl flex items-center w-full p-3 text-left transition-all ease-in border-b border-solid cursor-pointer border-slate-100 text-slate-700 rounded-t-1 group "
        onClick={onToggle}
      >
        <span className="text-[#719fa9] tracking-normal leading-relaxed ">
          {question}
        </span>
        <i
          className={`absolute text-[#719fa9] right-0 pt-1 text-xs fa ${
            isOpen ? "fa-minus" : "fa-plus"
          }`}
        ></i>
      </button>
    </h6>
    <div
      className={`overflow-hidden transition-all duration-300 ease-in-out ${
        isOpen ? "h-auto" : "h-0"
      }`}
    >
      <div className="p-4 text-xl tracking-wide flex items-center w-full p-4 text-left transition-all ease-in border-b border-solid cursor-pointer border-slate-100 text-slate-700 rounded-t-1 group text-dark-500">
        {answer}
      </div>
    </div>
  </div>
);

export default function FAQ() {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "What types of academic assignments does Edumate assist with?",
      answer:
        "At Edumate, we offer expert assistance for a wide range of academic assignments, including research papers, essays, and problem sets. No matter the subject or complexity, our team is equipped to deliver high-quality, timely, and reliable support tailored to meet your unique needs.",
    },
    {
      question:
        "How does Edumate ensure the quality and reliability of its services?",
      answer:
        "Edumate prides itself on delivering precision, personalized guidance, and insightful feedback for all academic assignments. Our team of experts is dedicated to maintaining high standards of quality and reliability, ensuring that your assignments are handled with utmost care and professionalism.",
    },
    {
      question:
        "Can Edumate provide tutoring services in addition to assignment assistance?",
      answer:
        "Yes, Edumate offers personalized tutoring services in any subject. Our tutors are available to provide one-on-one tutoring either in person or online, making it convenient for you to receive the help you need, regardless of your location or schedule.",
    },
    {
      question:
        "How can I benefit from Edumate's services for my academic needs?",
      answer:
        "You benefit from our services, simply reach out to us with your assignment details or tutoring needs. Our team will work closely with you to understand your requirements and provide tailored assistance that enhances your educational achievements.",
    },
    {
      question:
        "What makes Edumate different from other academic assistance services?",
      answer:
        "Edumate stands out due to our commitment to personalized guidance and insightful feedback. We focus on making your academic journey smoother and more successful by providing high-quality, tailored support that meets your unique needs. Our experienced team ensures precision and reliability in every service we offer, whether it's assignment assistance or personalized tutoring.",
    },
  ];

  return (
    <section class="text-gray-900 bg-gray-200 border-gray-100 shadow  xl:p-8  ">
      <div class="py-4 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div class="mb-5">
          <p class="text-gray-500 sm:text-xl text-center ">FAQ</p>
          <h2 class="mb-4 text-4xl text-center tracking-tight font-extrabold text-[#719fa9]  ">
            Frequently Asked Questions
          </h2>
        </div>
        {faqData.map((faq, index) => (
          <FAQItem
            key={index}
            question={faq.question}
            answer={faq.answer}
            isOpen={openIndex === index}
            onToggle={() => handleToggle(index)}
          />
        ))}
      </div>
    </section>
  );
}
