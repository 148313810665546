import React, { useState } from "react";
import { useUser } from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { getUserRole } from "../../firebase/Functions";

export default function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { login, user } = useUser();

  const navigate = useNavigate();

  const redirectBasedOnRole = async (user) => {
    try {
      const role = await getUserRole(user);
      switch (role) {
        case "Admin":
          navigate("/admin");
          break;
        case "student":
          navigate("/default");
          break;
        default:
          navigate("/login");
      }
    } catch (error) {
      console.error("Error redirecting based on role:", error.message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await login(formData.email, formData.password);
      setLoading(false);
    } catch (error) {
      if (error.code === "auth/invalid-credential") {
        setError("Invalid credentials");
      }
      if (error.code === "auth/too-many-requests") {
        setError("User account has been disabled, please reset");
      } else {
        setError("An error occurred. Please try again later.");
      }
      setFormData({
        email: "",
        password: "",
        rememberMe: false,
      });
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  return (
    <div className="bg-gray-200 common-margin mt-4">
      <div className="container">
        <form onSubmit={handleSubmit} className="max-w-xs mx-auto login">
          {error && (
            <div
              class="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50"
              role="alert"
            >
              {error}
            </div>
          )}

          <div className="mb-4 mt-2">
            <label
              htmlFor="email"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              Your email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              placeholder="edumate@icloud.com"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Your password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm  focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              placeholder="***********"
              required
            />
          </div>
          <button
            type="submit"
            className="text-white signupBtn mb-3 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium  text-sm w-full px-5 py-2.5 text-center"
          >
            {loading ? (
              <i className="fas fa-spinner fa-spin mr-1"></i>
            ) : (
              <i className="fas fa-lock mr-2"></i>
            )}
            {loading ? "Validating..." : "Login"}
          </button>
          <Link to="/reset-password" className="text-sm text-gray-500">
            Forgot password?
          </Link>
        </form>
      </div>
    </div>
  );
}
