import React from "react";

export default function Terms() {
  return (
    <div className="bg-gray-200 common-margin">
      <div className="container">
        <div class="mx-auto max-w-screen-md text-center mb-5 lg:mb-12">
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-[#719fa9] ">
            Terms and Conditions
          </h2>
          <p class="mb-5 font-light text-gray-500 sm:text-xl"></p>
        </div>
      </div>
    </div>
  );
}
