import React from "react";

export default function StudentLayout({ children }) {
  return (
    <div>
      <div className="admin-content bg-gray-200">
        <div className="admin-main bg-gray-200">{children}</div>
      </div>
    </div>
  );
}
