import React from "react";
import Nav from "../components/common/nav/Nav";
import Footer from "../components/common/footer/Footer";

export default function GeneralLayout({ children }) {
  return (
    <div>
      <Nav />
      {children}
      <Footer />
    </div>
  );
}
