import React from "react";
import { Pagination } from "react-bootstrap";

function MyPagination({ currentPage, pageCount, handlePageClick }) {
  return (
    <Pagination>
      <Pagination.Prev
        onClick={() => handlePageClick(currentPage - 1)}
        disabled={currentPage === 1} // Adjust based on your page numbering (assuming 1-based index)
      />
      {[...Array(pageCount)].map((_, index) => (
        <Pagination.Item
          key={index + 1}
          active={index + 1 === currentPage}
          onClick={() => handlePageClick(index + 1)}
        >
          {index + 1}
        </Pagination.Item>
      ))}
      <Pagination.Next
        onClick={() => handlePageClick(currentPage + 1)}
        disabled={currentPage === pageCount} // Adjust based on your page numbering (assuming 1-based index)
      />
    </Pagination>
  );
}

export default MyPagination;
