import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { getAllQuizzes } from "../../../../firebase/Quiz";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Quizzes({ onQuizClick }) {
  const [quizzes, setQuizzes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchQuizzes();
  }, []);

  const fetchQuizzes = async () => {
    try {
      setLoading(true);
      const fetchedQuizzes = await getAllQuizzes();
      setQuizzes(fetchedQuizzes);
    } catch (error) {
      console.error("Error fetching quizzes:", error);
      toast.error("Failed to fetch quizzes.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="md:container">
      <div className="text-textcolor">
        <div className="flex justify-between mt-3 items-center mb-3">
          <h2 className="text-textcolor p-1 text-xl font-bold">
            <i className="fas fa-list-ul mr-2"></i> Quizzes
          </h2>
        </div>
        <div className="card-body">
          {loading ? (
            <div
              className="flex justify-center items-center"
              style={{ height: "20vh" }}
            >
              <div className="text-center text-gray-300">
                <Spinner
                  animation="border"
                  className="text-textcolor"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            </div>
          ) : quizzes.length === 0 ? (
            <div className="bg-cardsButtons py-16">
              <div className="text-center">
                <p className="text-textcolor text-2xl font-bold">Coming Soon</p>
                <p className="text-textcolor text-sm">
                  Stay tuned for our exciting quizzes, and prizes to be won!
                </p>
              </div>
            </div>
          ) : (
            quizzes.map((quiz) => (
              <div
                key={quiz.id}
                className="flex justify-between items-center bg-cardsButtons py-3 mb-3 cursor-pointer"
                onClick={() => onQuizClick(quiz)}
              >
                <div className="flex items-center">
                  <img
                    src="../../../logo.PNG"
                    alt="Avatar"
                    className="border ml-2 p-1 md:inline-block w-11 h-11 mr-3 cursor-pointer"
                  />
                  <div>
                    <p className="text-textcolor text-lg">{quiz.quizName}</p>
                    <p className="text-textcolor text-xs">{quiz.grade}</p>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}
