import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { UserProvider } from "./context/AuthContext.js";
import AdminLayout from "./layouts/AdminLayout";
import GeneralLayout from "./layouts/GeneralLayout";
import Home from "./views/home/Home";
import Dashboard from "./views/dashboard/Dashboard";
import Contact from "./views/contact/Contact";
import Signup from "./views/signup/Signup";
import Terms from "./views/terms/Terms.jsx";
import Login from "./views/login/Login";
import Pricing from "./views/pricing/Pricing";
import Events from "./views/events/Events.jsx";
import StudentLayout from "./layouts/StudentLayout.jsx";
import Student_dashboard from "./views/dashboard/student/Student_dashboard.jsx";
import ResetPassword from "./views/reset-password/ResetPassword.jsx";

function App() {
  return (
    <div className="App">
      <UserProvider>
        <Router>
          <Routes>
            <Route
              path="/admin"
              element={
                <AdminLayout>
                  <ToastContainer />
                  <Dashboard />
                </AdminLayout>
              }
            />

            <Route
              path="/default"
              element={
                <StudentLayout>
                  <ToastContainer />
                  <Student_dashboard />
                </StudentLayout>
              }
            />

            <Route
              path="/contact"
              element={
                <GeneralLayout>
                  <Contact />
                </GeneralLayout>
              }
            />

            <Route
              path="/terms"
              element={
                <GeneralLayout>
                  <Terms />
                </GeneralLayout>
              }
            />

            <Route
              path="/events"
              element={
                <GeneralLayout>
                  <Events />
                </GeneralLayout>
              }
            />
            <Route
              path="/signup"
              element={
                <GeneralLayout>
                  <Signup />
                </GeneralLayout>
              }
            />
            <Route
              path="/login"
              element={
                <GeneralLayout>
                  <Login />
                </GeneralLayout>
              }
            />

            <Route
              path="/reset-password"
              element={
                <GeneralLayout>
                  <ToastContainer />
                  <ResetPassword />
                </GeneralLayout>
              }
            />
            <Route
              path="/pricing"
              element={
                <GeneralLayout>
                  <Pricing />
                </GeneralLayout>
              }
            />
            <Route
              path="/"
              element={
                <GeneralLayout>
                  <Home />
                </GeneralLayout>
              }
            />
          </Routes>
        </Router>
      </UserProvider>
    </div>
  );
}

export default App;
