import React, { useState, useEffect } from "react";
import { Spinner, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const announcements = [
  {
    id: 1,
    date: "2024-07-01",
    time: "10:00 AM",
    content:
      "Brothers and Sisters, the time has come for each and every one of you to decide whether you are going to be the problem or whether you are going to be the solution! (That's right!) You must choose Brothers, you must choose! It takes five seconds, five seconds of decision, five seconds to realize your purpose here on the planet! It takes five seconds to realize that it's time to move, it's time to get down with it!",
  },
  {
    id: 2,
    date: "2024-07-02",
    time: "11:00 AM",
    content: "Here is another announcement. Stay tuned for more updates!",
  },
];

export default function Announcement() {
  const [loading, setLoading] = useState(true);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setLoading(false);
    };

    fetchData();
  }, []);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : announcements.length - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex < announcements.length - 1 ? prevIndex + 1 : 0
    );
  };

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "80vh" }}
      >
        <Spinner animation="border" className="text-textcolor" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }

  const currentAnnouncement = announcements[currentIndex];

  return (
    <section className="md:container p-1">
      <div className="bg-backgroundMain text-white">
        <div className="flex items-center justify-between p-4 bg-sidebar">
          <div className="flex items-center">
            <div className="p-2 rounded-full">
              <img
                src="../../../../logo.PNG"
                alt="menu icon"
                className="w-10 h-10 border-2 border-gray-300 p-1 shadow-sm"
              />
            </div>
            <h2 className="text-cardsButtons text-2xl font-bold">
              Announcements
            </h2>
          </div>
        </div>
        <div className="p-4 bg-cardsButtons mb-3">
          <div className="text-lg font-extrabold text-textcolor mt-2">
            <i className="fas fa-graduation-cap mr-2"></i>
            Edumate Announcements
          </div>
          <div className="items-center mt-2">
            <div className="w-full">
              <p className="text-textcolor text-xl">
                {currentAnnouncement.content}
              </p>
              <p className="text-green-500 text-sm mt-3">
                <i className="fa fa-calendar mr-2"></i>
                {currentAnnouncement.date} at {currentAnnouncement.time}
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-between p-4 bg-sidebar">
          <Button variant="secondary" onClick={handlePrevious}>
            <i className="fa fa-chevron-left fa-sm mr-2"></i> Prev{" "}
          </Button>
          <Button variant="secondary" onClick={handleNext}>
            Next <i className="fa fa-chevron-right fa-sm ml-2"></i>{" "}
          </Button>
        </div>
      </div>
    </section>
  );
}
