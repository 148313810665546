import {
  collection,
  doc,
  getFirestore,
  addDoc,
  getDocs,
  getDoc,
  deleteDoc,
} from "firebase/firestore";

const db = getFirestore();

/**
 * @param {string} quizName - The name of the quiz.
 * @param {string} grade - The grade for the quiz.
 * @param {string} paper - The paper associated with the quiz.
 * @param {Object} quiz - The quiz object containing questions and answers.
 * @returns {Promise<boolean>} - A promise indicating whether the operation was successful.
 */
export const saveQuizData = async (quizName, grade, paper, quiz) => {
  try {
    const docRef = await addDoc(collection(db, "quizzes"), {
      quizName,
      grade,
      paper,
      quiz,
      createdAt: new Date().toISOString(),
    });
    console.log("Document written with ID: ", docRef.id);
    return true;
  } catch (error) {
    console.error("Error adding document: ", error);
    return false;
  }
};

/**
 * Retrieves all quizzes from the Firestore 'quizzes' collection.
 * @returns {Promise<Array>}
 */
export const getAllQuizzes = async () => {
  try {
    const querySnapshot = await getDocs(collection(db, "quizzes"));
    const quizzes = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return quizzes;
  } catch (error) {
    console.error("Error retrieving quizzes: ", error);
    return [];
  }
};

/**
 * Retrieves a quiz by its ID from the Firestore 'quizzes' collection.
 * @param {string} quizId - The ID of the quiz to retrieve.
 * @returns {Promise<Object|null>} - A promise that resolves to the quiz object or null if not found.
 */
export const getQuizById = async (quizId) => {
  try {
    const quizDoc = await getDoc(doc(db, "quizzes", quizId));
    if (quizDoc.exists()) {
      return { id: quizDoc.id, ...quizDoc.data() };
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error getting document: ", error);
    return null;
  }
};

/**
 * Updates a quiz by its ID in the Firestore 'quizzes' collection.
 * @param {string} quizId - The ID of the quiz to update.
 * @param {Object} updatedData - The new data for the quiz.
 * @returns {Promise<boolean>} - A promise indicating whether the operation was successful.
 */
export const updateQuizById = async (quizId, updatedData) => {
  try {
    const quizRef = doc(db, "quizzes", quizId);
    await updateDoc(quizRef, updatedData);
    return true;
  } catch (error) {
    console.error("Error updating document: ", error);
    return false;
  }
};

/**
 * @param {string} quizId - The ID of the quiz to delete.
 * @returns {Promise<boolean>} - A promise indicating whether the operation was successful.
 */
export const deleteQuizById = async (quizId) => {
  try {
    await deleteDoc(doc(db, "quizzes", quizId));
    return true;
  } catch (error) {
    console.error("Error deleting document: ", error);
    return false;
  }
};
