import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer className="bg-gray-900">
      <div className="content footer">
        <div className="top">
          <div className="logo-details">
            <span className="logo_name">Edumate</span>
          </div>
          <div className="media-icons">
            <a href="#">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="#">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="#">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="#">
              <i className="fab fa-linkedin-in"></i>
            </a>
            <a href="#">
              <i className="fab fa-youtube"></i>
            </a>
          </div>
        </div>

        <div className="link-boxes">
          <ul className="box">
            <li className="link_name">Company</li>
            <li>
              <a
                href="#"
                className="block md:p-0 text-gray-200 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#719fa9] font-semibold leading-8"
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block md:p-0 text-gray-200 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#719fa9] font-semibold leading-8"
              >
                About Us
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block md:p-0 text-gray-200 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#719fa9] font-semibold leading-8"
              >
                Contact Us
              </a>
            </li>
          </ul>
          <ul className="box">
            <li className="link_name">Services</li>
            <li>
              <a
                href="#"
                className="block md:p-0 text-gray-200 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#719fa9] font-semibold leading-8"
              >
                Research Papers
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block md:p-0 text-gray-200 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#719fa9] font-semibold leading-8"
              >
                Essays
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block md:p-0 text-gray-200 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#719fa9] font-semibold leading-8"
              >
                Problem Sets
              </a>
            </li>
          </ul>
          <ul className="box">
            <li className="link_name">Account</li>
            <li>
              <a
                href="#"
                className="block md:p-0 text-gray-200 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#719fa9] font-semibold leading-8"
              >
                Profile
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block md:p-0 text-gray-200 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#719fa9] font-semibold leading-8"
              >
                My Account
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block md:p-0 text-gray-200 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#719fa9] font-semibold leading-8"
              >
                Preferences
              </a>
            </li>
          </ul>
          <ul className="box">
            <li className="link_name">Resources</li>
            <li>
              <a
                href="#"
                className="block md:p-0 text-gray-200 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#719fa9] font-semibold leading-8"
              >
                Blog
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block md:p-0 text-gray-200 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#719fa9] font-semibold leading-8"
              >
                FAQs
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block md:p-0 text-gray-200 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-[#719fa9] font-semibold leading-8"
              >
                Guides
              </a>
            </li>
          </ul>
          <ul className="box input-box">
            <li className="link_name">Subscribe</li>
            <li>
              <input
                type="text text-textcolor"
                placeholder="Enter your email"
              />
            </li>
            <li>
              <input type="button" value="Subscribe" />
            </li>
          </ul>
        </div>
      </div>
      <div className="bottom-details bg-gray-800">
        <div className="bottom_text">
          <span className="copyright_text">
            Copyright © 2024 <a href="#">Edumate.</a> All rights reserved
          </span>
          <span className="policy_terms">
            <a href="#">Privacy Policy</a>
            <a>
              <Link to="/terms">Terms & Conditions</Link>
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
}
