import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import { saveQuizData } from "../../../firebase/Quiz";
import { toast } from "react-toastify";

export default function AddQuiz() {
  const [quiz, setQuiz] = useState([
    {
      question: "",
      questionType: "",
      answers: [],
      correctAnswer: [],
      explanation: "",
      isOpen: false,
    },
  ]);

  const [quizName, setQuizName] = useState("");
  const [grade, setGrade] = useState("");
  const [paper, setPaper] = useState("");
  const [errors, setErrors] = useState({});

  const handleAddQuestion = () => {
    setQuiz([
      ...quiz,
      {
        question: "",
        questionType: "",
        answers: [],
        correctAnswer: [],
        explanation: "",
        isOpen: false,
      },
    ]);
  };

  const handleAddAnswer = (index) => {
    const updatedQuiz = [...quiz];
    updatedQuiz[index].answers.push("");
    setQuiz(updatedQuiz);
  };

  const handleQuestionChange = (index, field, value) => {
    const updatedQuiz = [...quiz];
    updatedQuiz[index][field] = value;
    setQuiz(updatedQuiz);
  };

  const handleAnswerChange = (questionIndex, answerIndex, value) => {
    const updatedQuiz = [...quiz];
    updatedQuiz[questionIndex].answers[answerIndex] = value;
    setQuiz(updatedQuiz);
  };

  const handleCorrectAnswerChange = (questionIndex, answerIndex) => {
    const updatedQuiz = [...quiz];
    const { questionType, correctAnswer } = updatedQuiz[questionIndex];

    if (questionType === "multipleChoice") {
      updatedQuiz[questionIndex].correctAnswer = [answerIndex];
    } else if (questionType === "multipleAnswer") {
      if (correctAnswer.includes(answerIndex)) {
        updatedQuiz[questionIndex].correctAnswer = correctAnswer.filter(
          (idx) => idx !== answerIndex
        );
      } else {
        updatedQuiz[questionIndex].correctAnswer = [
          ...correctAnswer,
          answerIndex,
        ];
      }
    }

    setQuiz(updatedQuiz);
  };

  const handleRemoveAnswer = (questionIndex, answerIndex) => {
    const updatedQuiz = [...quiz];
    updatedQuiz[questionIndex].answers.splice(answerIndex, 1);
    updatedQuiz[questionIndex].correctAnswer = updatedQuiz[
      questionIndex
    ].correctAnswer.filter((idx) => idx !== answerIndex);
    setQuiz(updatedQuiz);
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuiz = [...quiz];
    updatedQuiz.splice(index, 1);
    setQuiz(updatedQuiz);
  };

  const handleSaveQuiz = async () => {
    const newErrors = {};

    if (!quizName.trim()) {
      newErrors.quizName = "Quiz name is required.";
    }

    if (!grade.trim()) {
      newErrors.grade = "Grade is required.";
    }

    if (!paper.trim()) {
      newErrors.paper = "Paper is required.";
    }

    quiz.forEach((question, index) => {
      if (!question.question.trim()) {
        newErrors[`question_${index}`] = "Question is required.";
      }

      if (!question.questionType) {
        newErrors[`questionType_${index}`] = "Question type is required.";
      }

      if (
        question.questionType !== "fillAnswer" &&
        question.answers.length === 0
      ) {
        newErrors[`answers_${index}`] = "At least one answer is required.";
      }

      if (
        question.questionType === "fillAnswer" &&
        !question.correctAnswer[0]
      ) {
        newErrors[`correctAnswer_${index}`] = "Correct answer is required.";
      }

      if (
        question.questionType !== "fillAnswer" &&
        question.correctAnswer.length === 0
      ) {
        newErrors[`correctAnswer_${index}`] =
          "At least one correct answer is required.";
      }
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      console.log("Quiz saved successfully:", { quizName, grade, paper, quiz });
      alert("Quiz saved successfully!");
      try {
        const success = await saveQuizData(quizName, grade, paper, quiz);
        if (success) {
          console.log("Quiz data saved successfully.");
          toast.success("Quiz saved successfully!");

          setQuiz([
            {
              question: "",
              questionType: "",
              answers: [],
              correctAnswer: [],
              explanation: "",
              isOpen: false,
            },
          ]);
          setQuizName("");
          setGrade("");
          setPaper("");
          setErrors({});
        } else {
          console.error("Failed to save quiz data.");
          toast.error("Failed to save quiz data.");
        }
      } catch (error) {
        console.error("An unexpected error occurred:", error);
        toast.error("An unexpected error occurred.");
      }
    }
  };

  const toggleQuestion = (index) => {
    const updatedQuiz = [...quiz];
    updatedQuiz[index].isOpen = !updatedQuiz[index].isOpen;
    setQuiz(updatedQuiz);
  };

  const options = {
    height: 160,
    menubar: true,
    external_plugins: {
      tiny_mce_wiris: `${window.location.href}/node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
    },
    plugins: [
      "advlist autolink lists link image charmap print preview anchor",
      "searchreplace visualblocks code fullscreen",
      "insertdatetime media table paste code help wordcount",
    ],
    toolbar: [
      "blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat |" +
        "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry ",
    ],
    htmlAllowedTags: [".*"],
    htmlAllowedAttrs: [".*"],
  };

  return (
    <div className="bg-cardsButtons p-3">
      <div className="mb-4">
        <label
          htmlFor="name"
          className="block mb-2 text-sm font-semibold text-textcolor"
        >
          <i className="fas fa-book mr-1"></i> Quiz name
        </label>
        <input
          type="text"
          id="name"
          name="quizName"
          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          placeholder="Edumate"
          value={quizName}
          onChange={(e) => setQuizName(e.target.value)}
        />
        {errors.quizName && (
          <p className="text-red-500 text-xs mt-1">{errors.quizName}</p>
        )}
      </div>
      <div className="mb-4">
        <label
          htmlFor="Grade"
          className="block mb-2 text-sm font-semibold text-textcolor"
        >
          Grade
        </label>
        <input
          type="text"
          id="grade"
          name="grade"
          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          placeholder="Grade 10"
          value={grade}
          onChange={(e) => setGrade(e.target.value)}
        />
        {errors.grade && (
          <p className="text-red-500 text-xs mt-1">{errors.grade}</p>
        )}
      </div>
      <div className="mb-4">
        <label
          htmlFor="Paper"
          className="block mb-2 text-sm font-semibold text-textcolor"
        >
          Paper
        </label>
        <input
          type="text"
          id="paper"
          name="paper"
          className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          placeholder="Paper 1"
          value={paper}
          onChange={(e) => setPaper(e.target.value)}
        />
        {errors.paper && (
          <p className="text-red-500 text-xs mt-1">{errors.paper}</p>
        )}
      </div>

      {quiz.map((questionSet, index) => (
        <div key={index} className="mb-3 p-2 features">
          <div className="flex justify-between items-center">
            {!questionSet.isOpen && (
              <h3 className="text-sm font-semibold text-gray-900">
                <i className="fas fa-info-circle mr-2"></i>
                Question {index + 1}
              </h3>
            )}
            <button
              className="bg-sidebar text-white text-xs rounded px-4 py-2"
              onClick={() => toggleQuestion(index)}
            >
              {questionSet.isOpen ? (
                <i className="fas fa-eye-slash mr-2"></i>
              ) : (
                <i className="fas fa-eye mr-2"></i>
              )}
              {questionSet.isOpen ? "Hide" : "Show"}
            </button>
          </div>
          {questionSet.isOpen && (
            <form>
              <div className="mb-4">
                <label
                  htmlFor={`question_${index}`}
                  className="block mb-2 text-sm font-semibold text-gray-900"
                >
                  Question
                </label>
                <Editor
                  value={questionSet.question}
                  onEditorChange={(content) =>
                    handleQuestionChange(index, "question", content)
                  }
                  init={options}
                />
                {errors[`question_${index}`] && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors[`question_${index}`]}
                  </p>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="questionType"
                  className="block mb-2 text-sm font-semibold text-gray-900"
                >
                  Question type
                </label>
                <select
                  id="questionType"
                  value={questionSet.questionType}
                  onChange={(e) =>
                    handleQuestionChange(index, "questionType", e.target.value)
                  }
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                >
                  <option value="">Select question type</option>
                  <option value="multipleChoice">Multiple Choice</option>
                  <option value="multipleAnswer">Multiple Answer</option>
                  <option value="fillAnswer">Fill in the Answer</option>
                </select>
                {errors[`questionType_${index}`] && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors[`questionType_${index}`]}
                  </p>
                )}
              </div>

              {questionSet.answers.map((answer, answerIndex) => (
                <div key={answerIndex} className="mb-4">
                  <label
                    htmlFor={`answer_${index}_${answerIndex}`}
                    className="block mb-2 text-sm font-semibold text-gray-900"
                  >
                    Answer {answerIndex + 1}
                  </label>
                  <Editor
                    value={answer}
                    onEditorChange={(content) =>
                      handleAnswerChange(index, answerIndex, content)
                    }
                    init={options}
                  />
                  <Button
                    onClick={() => handleRemoveAnswer(index, answerIndex)}
                    variant="danger"
                    className="px-2 py-1 mt-2 bg-red-500 text-white text-xs rounded"
                  >
                    <i className="fas fa-trash-alt mr-1"></i> Remove answer
                  </Button>
                </div>
              ))}

              {(questionSet.questionType === "multipleChoice" ||
                questionSet.questionType === "multipleAnswer") && (
                <div className="mb-4">
                  <label
                    htmlFor="correctAnswer"
                    className="block mb-2 text-sm font-semibold text-textcolor"
                  >
                    Correct Answer(s)
                  </label>
                  {questionSet.answers.map((_, answerIndex) => (
                    <div key={answerIndex} className="mb-2">
                      <Form.Check
                        id={`correctAnswer_${index}_${answerIndex}`}
                        name={`correctAnswer_${index}`}
                        checked={questionSet.correctAnswer.includes(
                          answerIndex
                        )}
                        onChange={() =>
                          handleCorrectAnswerChange(index, answerIndex)
                        }
                        type="checkbox"
                        label={`Answer ${answerIndex + 1}`}
                        className="mr-2"
                      />
                    </div>
                  ))}
                  {errors[`correctAnswer_${index}`] && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors[`correctAnswer_${index}`]}
                    </p>
                  )}
                </div>
              )}

              {questionSet.questionType === "fillAnswer" && (
                <div className="mb-4">
                  <label
                    htmlFor={`correctAnswer_${index}`}
                    className="block mb-2 text-sm font-semibold text-gray-900"
                  >
                    Correct Answer
                  </label>
                  <Editor
                    value={questionSet.correctAnswer[0] || ""}
                    onEditorChange={(content) =>
                      handleQuestionChange(index, "correctAnswer", [content])
                    }
                    init={options}
                  />
                  {errors[`correctAnswer_${index}`] && (
                    <p className="text-red-500 text-xs mt-1">
                      {errors[`correctAnswer_${index}`]}
                    </p>
                  )}
                </div>
              )}

              <div className="mb-4">
                <label
                  htmlFor="explanation"
                  className="block mb-2 text-sm font-semibold text-gray-900"
                >
                  Explanation
                </label>
                <Editor
                  value={questionSet.explanation}
                  onEditorChange={(content) =>
                    handleQuestionChange(index, "explanation", content)
                  }
                  init={options}
                />
              </div>

              {questionSet.questionType !== "fillAnswer" && (
                <Button
                  onClick={() => handleAddAnswer(index)}
                  variant="primary"
                  className="px-4 py-2 bg-sidebar text-white text-xs rounded mr-2"
                >
                  <i className="fas fa-plus mr-2"></i>
                  Add Answer
                </Button>
              )}

              <Button
                onClick={() => handleRemoveQuestion(index)}
                variant="danger"
                className="px-4 py-2 bg-red-500 text-white text-xs rounded"
              >
                <i className="fas fa-trash fa-xs mr-2"></i>
                Remove Question
              </Button>
            </form>
          )}
        </div>
      ))}
      <div className="flex mt-2 justify-between">
        <Button
          onClick={handleAddQuestion}
          variant="primary"
          className="px-4 py-2 bg-sidebar text-white text-xs rounded"
        >
          <i className="fas fa-plus mr-2"></i>
          Add Question
        </Button>
        <Button
          onClick={handleSaveQuiz}
          variant="success"
          className="px-4 py-2 bg-green-500 text-white text-xs rounded"
        >
          <i className="fas fa-save mr-2"></i>
          Save Quiz
        </Button>
      </div>
    </div>
  );
}
