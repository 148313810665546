import React from "react";
const AdminLayout = ({ children }) => {
  return (
    <div>
      <div className="admin-content bg-gray-200">
        <div className="admin-main bg-gray-200">{children}</div>
      </div>
    </div>
  );
};

export default AdminLayout;
