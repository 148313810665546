import React, { useState, useEffect } from "react";
import { Button, Form, Card, Modal } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";

export default function QuizView({ quiz, onBack, quizName }) {
  const [loading, setLoading] = useState(true);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [score, setScore] = useState(0);
  const [graded, setGraded] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [correctAnswer, setCorrectAnswer] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [explanation, setExplanation] = useState("");

  useEffect(() => {
    setLoading(false);
  }, [quiz]);

  useEffect(() => {
    calculateScore();
  }, [userAnswers, quiz]);

  const handleAnswerChange = (questionIndex, answer) => {
    setUserAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionIndex]: answer,
    }));

    setSelectedAnswer(answer);
    const correctAnswerIndex = parseInt(
      quiz.quiz[questionIndex].correctAnswer[0],
      10
    );
    setCorrectAnswer(correctAnswerIndex);
  };

  const calculateScore = () => {
    if (!quiz) return;

    let newScore = 0;
    quiz.quiz.forEach((question, index) => {
      const userAnswer = userAnswers[index];
      const correctAnswerIndex = parseInt(question.correctAnswer[0], 10);

      if (question.questionType === "multipleChoice") {
        if (userAnswer === correctAnswerIndex) {
          newScore += 1;
        }
      } else if (question.questionType === "fillAnswer") {
        if (
          userAnswer
            ?.toString()
            .trim()
            .replace(/<\/?[^>]+(>|$)/g, "")
            .toLowerCase() ===
          question.correctAnswer[0]
            .toString()
            .trim()
            .replace(/<\/?[^>]+(>|$)/g, "")
            .toLowerCase()
        ) {
          newScore += 1;
        }
      } else if (question.questionType === "multipleAnswer") {
        const correctAnswers = question.correctAnswer.map((ans) =>
          parseInt(ans, 10)
        );
        if (
          userAnswer &&
          correctAnswers.every((ans) => userAnswer.includes(ans))
        ) {
          newScore += 1;
        }
      }
    });

    setScore(newScore);
  };

  const handleNext = () => {
    if (currentQuestionIndex < quiz.quiz.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setSelectedAnswer(null);
      setCorrectAnswer(null);
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
      setSelectedAnswer(null);
      setCorrectAnswer(null);
    }
  };

  const handleGradeQuiz = () => {
    setGraded(true);
  };

  const handleRetakeQuiz = () => {
    setUserAnswers({});
    setCurrentQuestionIndex(0);
    setScore(0);
    setGraded(false);
    setSelectedAnswer(null);
    setCorrectAnswer(null);
  };

  const handleBack = () => {
    onBack();
  };

  const handleCloseModal = () => setShowModal(false);

  if (!quiz) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <p>Failed to load quiz data. Please try again later.</p>
      </div>
    );
  }

  const question = quiz.quiz[currentQuestionIndex];

  const options = {
    height: 160,
    menubar: true,
    external_plugins: {
      tiny_mce_wiris: `${window.location.href}/node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
    },
    plugins: [
      "advlist autolink lists link image charmap print preview anchor",
      "searchreplace visualblocks code fullscreen",
      "insertdatetime media table paste code help wordcount",
    ],
    toolbar: [
      "blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat |" +
        "tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry ",
    ],
    htmlAllowedTags: [".*"],
    htmlAllowedAttrs: [".*"],
  };

  return (
    <div className="home p-0">
      <div className="bg-backgroundMain text-white">
        <div className="flex items-center justify-between bg-sidebar">
          <div className="flex items-center">
            <div className="p-2 rounded-full">
              <img
                src="../../../../logo.PNG"
                alt="menu icon"
                className="w-10 h-10 border-2 border-gray-300 p-1 shadow-sm"
              />
            </div>
            <h2 className="text-cardsButtons text-l font-bold">
              {quiz.quizName}
            </h2>
          </div>
          <Button
            variant="secondary"
            className="text-xs text-textcolor bg-cardsButtons mr-2"
            onClick={handleBack}
          >
            <i className="fas fa-arrow-left fa-xs mr-1"></i> Back
          </Button>
        </div>
        <div className="bg-cardsButtons mb-3">
          <div className="items-center">
            <div className="p-3 bg-cardsButtons mb-3">
              <div className="text-lg font-extrabold text-textcolor mt-2">
                <i className="fas fa-graduation-cap mr-2"></i>
                {quiz.grade}
              </div>
              <div className="mt-2">
                <div className="w-full">
                  <p className="text-textcolor text-lg">
                    Answer the quiz questions to the best of your ability. Good
                    luck!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center flex-column">
        <Card className="shadow-sm">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <span className="text-muted font-semibold">
              Question {currentQuestionIndex + 1} of {quiz.quiz.length}
            </span>
            {graded && (
              <span className="font-semibold text-textcolor">
                Score: {score}
              </span>
            )}
          </Card.Header>
          <Card.Body className="p-1">
            <Card.Text
              className="text-textcolor mt-1"
              dangerouslySetInnerHTML={{ __html: question.question }}
            />

            {question.questionType === "multipleAnswer" && (
              <Form className="mt-3 mb-3 text-textcolor">
                {question.answers.map((answer, idx) => (
                  <Form.Check
                    key={idx}
                    id={`checkbox_${idx}`}
                    name={`checkbox_${idx}`}
                    type="checkbox"
                    label={
                      <span dangerouslySetInnerHTML={{ __html: answer }} />
                    }
                    checked={
                      userAnswers[currentQuestionIndex]?.includes(idx) || false
                    }
                    onChange={() => {
                      const currentAnswers =
                        userAnswers[currentQuestionIndex] || [];
                      const updatedAnswers = currentAnswers.includes(idx)
                        ? currentAnswers.filter((a) => a !== idx)
                        : [...currentAnswers, idx];
                      handleAnswerChange(currentQuestionIndex, updatedAnswers);
                    }}
                    disabled={graded}
                  />
                ))}
              </Form>
            )}

            {question.questionType === "multipleChoice" && (
              <Form className="mt-3 mb-3 text-textcolor">
                {question.answers.map((answer, idx) => (
                  <Form.Check
                    key={idx}
                    id={`radio_${idx}`}
                    name={`radio_${currentQuestionIndex}`}
                    type="radio"
                    label={
                      <span dangerouslySetInnerHTML={{ __html: answer }} />
                    }
                    checked={userAnswers[currentQuestionIndex] === idx}
                    onChange={() =>
                      handleAnswerChange(currentQuestionIndex, idx)
                    }
                    disabled={graded}
                  />
                ))}
              </Form>
            )}

            {question.questionType === "fillAnswer" && (
              <div>
                <Editor
                  value={userAnswers[currentQuestionIndex] || ""}
                  init={options}
                  className="mt-3 mb-3"
                  onEditorChange={(content) =>
                    handleAnswerChange(currentQuestionIndex, content)
                  }
                  disabled={graded}
                />
              </div>
            )}

            {graded && (
              <div className="mt-3 bg-gray-100 p-3 rounded-lg shadow-inner">
                <h5 className="text-textcolor font-semibold">
                  <i className="fas fa-check-circle mr-2 text-green-600"></i>
                  Correct Answer:
                </h5>
                {question.questionType === "fillAnswer" ? (
                  <p className="mt-2 text-green-600 font-medium">
                    {question.correctAnswer[0]}
                  </p>
                ) : (
                  <p
                    className="mt-2 text-green-600 font-medium"
                    dangerouslySetInnerHTML={{
                      __html: question.answers[question.correctAnswer[0]],
                    }}
                  ></p>
                )}
              </div>
            )}

            {graded && (
              <div className="mt-3 bg-gray-100 p-3 mb-2 rounded-lg shadow-inner">
                <h5 className="text-textcolor font-semibold">
                  <i className="fas fa-info-circle mr-2 text-blue-600"></i>
                  Explanation:
                </h5>
                <Card.Text
                  className="mt-2 text-blue-600 font-medium"
                  dangerouslySetInnerHTML={{ __html: question.explanation }}
                />
              </div>
            )}
          </Card.Body>
          <Card.Footer className="p-2 d-flex justify-content-between align-items-center">
            <Button
              variant="secondary"
              className="text-white text-xs bg-sidebar px-4 py-2 flex items-center transition duration-1000 ease-in-out"
              onClick={handlePrevious}
              disabled={currentQuestionIndex === 0}
            >
              <i className="fas fa-arrow-left fa-xs mr-2"></i> Prev
            </Button>
            <div>
              {graded ? (
                <div>
                  <Button
                    variant="secondary"
                    className="text-white text-xs bg-sidebar px-4 py-2 flex items-center transition duration-1000 ease-in-out"
                    onClick={handleRetakeQuiz}
                  >
                    <i className="fas fa-redo fa-xs mr-2"></i> Retake
                  </Button>
                </div>
              ) : (
                <div>
                  {currentQuestionIndex === quiz.quiz.length - 1 ? (
                    <Button
                      variant="success"
                      className="text-white text-xs bg-sidebar px-4 py-2 flex items-center transition duration-1000 ease-in-out"
                      onClick={handleGradeQuiz}
                    >
                      <i className="fas fa-check mr-1 fa-xs"></i> Grade Quiz
                    </Button>
                  ) : (
                    <Button
                      variant="primary"
                      className="text-white text-xs bg-sidebar px-4 py-2 flex items-center transition duration-1000 ease-in-out"
                      onClick={handleNext}
                    >
                      Next <i className="fas fa-arrow-right ml-1 fa-xs"></i>
                    </Button>
                  )}
                </div>
              )}
            </div>
          </Card.Footer>
        </Card>
      </div>
    </div>
  );
}
