import React, { useState, useEffect } from "react";
import { getCourses } from "../../../firebase/Videos";

export default function CourseList() {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const coursesData = getCourses()
          .then((videos) => {
            setCourses(videos);
          })
          .catch((error) => {
            console.error("Error: ", error);
          });

        setLoading(false);
      } catch (error) {
        console.error("Error fetching courses:", error);
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  return (
    <div className="md:container courseList">
      <div className="grid grid-cols-1 mb-5 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {courses.map((course, index) => (
          <div
            key={course.id}
            className="card p-0 features shadow-lg rounded-lg overflow-hidden relative"
          >
            <div className="relative">
              <img
                className="w-full h-36 object-cover"
                src="https://images.unsplash.com/photo-1445452916036-9022dfd33aa8?auto=format&fit=crop&w=1053&q=80&ixid=dW5zcGxhc2guY29Ozs7Ozs%3D"
                alt="Course Image"
              />
              <div className="absolute bottom-0 left-0 p-4 bg-sidebar bg-opacity-70 w-full">
                <h2 className="text-[#709ea9] text-xl tracking-tight font-bold ">
                  <i className="fas fa-book mr-2"></i>
                  {course.id}
                </h2>
              </div>
            </div>
            <div className="card-body p-3">
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center">
                  <img
                    className="w-8 h-8 border p-1 -ml-1 mr-2 rounded-full"
                    src="../../../../logo.PNG"
                    alt="Author Avatar"
                  />

                  <p className="text-gray-600 tracking-tigh">{course.author}</p>
                </div>
              </div>

              <div className="flex justify-between items-center mt-3">
                <p className="text-gray-600 text-sm sm:text-xs">
                  Estimated time: {course.time}
                </p>
              </div>
              <p className="text-gray-800 font-bold text-sm sm:text-xs mt-3">
                R{course.price}
              </p>
            </div>

            <div className="card-footer p-3 border-t">
              <div className="flex justify-between items-center">
                <button className="bg-green-500 text-white text-xs p-2 rounded flex items-center hover:bg-green-600 transition">
                  <i className="fas fa-upload mr-2"></i>Publish
                </button>
                <div className="flex space-x-2">
                  <button className="bg-blue-500 text-white text-xs p-2 rounded flex items-center hover:bg-blue-600 transition">
                    <i className="fas fa-pencil-alt mr-2"></i>Edit
                  </button>
                  <button className="bg-red-500 text-white text-xs p-2 rounded flex items-center hover:bg-red-600 transition">
                    <i className="fas fa-trash mr-2"></i>Delete
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
