import React from "react";

export default function Testimonals() {
  return (
    <div className="testimonials ">
      <section class="bg-gray-100 container  py-16">
        <div class=" px-4 mx-auto text-center max-w-screen-xl lg:px-6">
          <p class="text-gray-500 sm:text-xl text-center">
            CLIENT TESTIMONIALS
          </p>
          <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-[#719fa9]">
            Insights from Our Valued Clients
          </h2>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div class="placement_container -mt-5">
              <div class="placement_item_container">
                <a href="#" target="_blank" class="placement_stories_link">
                  <div class="placement_card zoom">
                    <div class="placement_text">
                      <h3 className=" text-lg font-semibold text-[#719fa9]">
                        Mukhetwa
                      </h3>

                      <p className="p-3 text-gray-500 text-lg font-normal lg:text-xl">
                        Isaac Mhlanga, a final-year student at the University of
                        Johannesburg, is renowned for his talent and motivation.
                        He passionately assists fellow students across diverse
                        fields such as Information Technology, mathematics, and
                        theory-based studies, showcasing his dedication to
                        learning and academic excellence.
                      </p>
                      <p className="mb-3 font-semibold text-[#719fa9]">
                        Student | University of Johannesburg
                      </p>
                    </div>

                    <div className="flex items-center placement_bottom">
                      <img
                        src="../../../../logo.PNG"
                        alt="Edumate Logo"
                        className="mr-2 h-10 w-auto pb-1"
                      />
                      <div className=" font-semibold text-start text-[#719fa9]">
                        EDUMATE
                        <span className="ml-2 text-sm font-normal tracking-wide text-gray-500">
                          Your Partner in Academic Excellence.
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div class="placement_container -mt-5">
              <div class="placement_item_container">
                <a href="#" target="_blank" class="placement_stories_link">
                  <div class="placement_card zoom">
                    <div class="placement_text">
                      <h3 className=" text-lg font-semibold text-[#719fa9]">
                        Bontle Mahlango
                      </h3>

                      <p className="p-3 text-gray-500 text-lg font-normal lg:text-xl">
                        Edumate provided exceptional assistance with my
                        assignments. Their expertise, dedication, and genuine
                        support made a significant impact on my understanding of
                        my assignments. I highly recoment Isaac Mhlanga as a
                        mentor who goes above and beyond to ensure student
                        success. Thank you for your invaluable support in my
                        leaning journey.
                      </p>
                      <p className="mb-3 font-semibold text-[#719fa9]">
                        Student | University of Johannesburg
                      </p>
                    </div>

                    <div className="flex items-center placement_bottom">
                      <img
                        src="../../../../logo.PNG"
                        alt="Edumate Logo"
                        className="mr-2 h-10 w-auto pb-1"
                      />
                      <div className=" font-semibold text-start text-[#719fa9]">
                        EDUMATE
                        <span className="ml-2 text-sm font-normal tracking-wide text-gray-500">
                          Your Partner in Academic Excellence.
                        </span>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
