import React, { useState, useRef } from "react";
import { uploadVideos } from "../../../firebase/Videos";

export default function AddCourses() {
  const [formValues, setFormValues] = useState({
    courseName: "",
    authorName: "",
    estimatedTime: "",
    price: "",
  });

  const [videos, setVideos] = useState([]);
  const [videoURLs, setVideoURLs] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [errors, setErrors] = useState({});
  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "videos") {
      setVideos([...videos, ...Array.from(files)]);
      fileInputRef.current.value = null;
    } else {
      setFormValues({
        ...formValues,
        [name]: value,
      });
    }
  };

  const removeVideo = (index) => {
    setVideos(videos.filter((_, i) => i !== index));
  };

  const validate = () => {
    let newErrors = {};
    if (!formValues.courseName)
      newErrors.courseName = "Course name is required";
    if (!formValues.authorName)
      newErrors.authorName = "Author name is required";
    if (!formValues.estimatedTime)
      newErrors.estimatedTime = "Estimated time is required";
    if (!formValues.price) newErrors.price = "Price is required"; // Added validation for price
    if (videos.length === 0)
      newErrors.videos = "At least one video file is required";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleProgress = (index, progress) => {
    setUploadProgress((prevProgress) => ({
      ...prevProgress,
      [index]: progress,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const uploadedVideoURLs = await uploadVideos(
          videos,
          formValues.courseName,
          formValues.authorName,
          formValues.estimatedTime,
          formValues.price,
          handleProgress
        );
        setVideoURLs(uploadedVideoURLs);
        console.log("Form submitted", {
          ...formValues,
          videoURLs: uploadedVideoURLs,
        });
      } catch (error) {
        console.error("Error uploading videos", error);
      }
    }
  };

  const handleDone = () => {
    console.log("Final submission", { ...formValues, videoURLs });
    setFormValues({
      courseName: "",
      authorName: "",
      estimatedTime: "",
      price: "",
    });
    setVideos([]);
    setVideoURLs([]);
    setUploadProgress({});
  };

  return (
    <div className="addCourse bg-cardsButtons p-3">
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            htmlFor="courseName"
            className="block mb-2 text-sm font-semibold text-gray-700"
          >
            <i className="fas fa-book mr-1"></i> Course Name
          </label>
          <input
            type="text"
            id="courseName"
            name="courseName"
            value={formValues.courseName}
            onChange={handleChange}
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder="Enter course name"
          />
          {errors.courseName && (
            <p className="text-red-500 text-xs mt-1">{errors.courseName}</p>
          )}
        </div>
        <div className="mb-4">
          <label
            htmlFor="authorName"
            className="block mb-2 text-sm font-semibold text-gray-700"
          >
            <i className="fas fa-user mr-1"></i> Author Name
          </label>
          <input
            type="text"
            id="authorName"
            name="authorName"
            value={formValues.authorName}
            onChange={handleChange}
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder="Enter author name"
          />
          {errors.authorName && (
            <p className="text-red-500 text-xs mt-1">{errors.authorName}</p>
          )}
        </div>
        <div className="mb-4">
          <label
            htmlFor="estimatedTime"
            className="block mb-2 text-sm font-semibold text-gray-700"
          >
            <i className="fas fa-clock mr-1"></i> Estimated Time
          </label>
          <input
            type="text"
            id="estimatedTime"
            name="estimatedTime"
            value={formValues.estimatedTime}
            onChange={handleChange}
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder="Enter estimated time"
          />
          {errors.estimatedTime && (
            <p className="text-red-500 text-xs mt-1">{errors.estimatedTime}</p>
          )}
        </div>
        <div className="mb-4">
          <label
            htmlFor="price"
            className="block mb-2 text-sm font-semibold text-gray-700"
          >
            <i className="fas fa-dollar-sign mr-1"></i> Price
          </label>
          <input
            type="text"
            id="price"
            name="price"
            value={formValues.price}
            onChange={handleChange}
            className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
            placeholder="Enter price"
          />
          {errors.price && (
            <p className="text-red-500 text-xs mt-1">{errors.price}</p>
          )}
        </div>
        <div className="mb-4">
          <label
            htmlFor="videos"
            className="block mb-2 text-sm font-semibold text-gray-700"
          >
            <i className="fas fa-video mr-1"></i> Upload Videos
          </label>
          <input
            type="file"
            id="videos"
            name="videos"
            accept="video/mp4"
            multiple
            onChange={handleChange}
            ref={fileInputRef}
            className="block w-full py-2 pl-2 text-sm text-gray-900 bg-gray-50 border border-gray-300 shadow-sm cursor-pointer focus:outline-none"
          />
          {errors.videos && (
            <p className="text-red-500 text-xs mt-1">{errors.videos}</p>
          )}
          <div className="mt-2">
            {videos.map((video, index) => (
              <div key={index} className="flex items-center mb-1">
                <p className="text-sm text-gray-700 mr-2">{video.name}</p>
                <button
                  type="button"
                  onClick={() => removeVideo(index)}
                  className="text-red-500 text-xs"
                >
                  Remove
                </button>
                {uploadProgress[index] !== undefined && (
                  <div className="ml-2 text-xs text-blue-500">
                    {uploadProgress[index].toFixed(2)}%
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="flex space-x-2">
          <button
            type="submit"
            className="bg-blue-500 hover:bg-blue-700 text-white text-xs p-2 rounded flex items-center"
          >
            <i className="fas fa-plus mr-2"></i> Add Course
          </button>
          <button
            type="button"
            onClick={handleDone}
            className="bg-green-500 hover:bg-green-700 text-white text-xs p-2 rounded flex items-center"
          >
            <i className="fas fa-check mr-2"></i> I'm Done
          </button>
        </div>
      </form>
    </div>
  );
}
