import React, { useState, useEffect } from "react";
import Home from "./Home";
import Assignments from "./Assignments";
import Transactions from "./Transactions";
import Users from "./Users";
import Quiz from "./quiz/Quiz";
import Notifications from "./Notifications";
import { fetchAllAssignments } from "../../firebase/Functions";
import Appoitments from "./Appoitments";
import Billing from "./Billing";

import { useUser } from "../../context/AuthContext";

export default function Dashboard() {
  const [assignments, setAssignments] = useState([]);
  const [activeLink, setActiveLink] = useState("dashboard");
  const [user, setUser] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleDarkMode = () => {
    setIsDarkMode((prevState) => !prevState);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen((prevState) => !prevState);
  };

  const { logout, extractUid, getUserByUid } = useUser();

  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  // Handler for user logout
  const logoutUser = async () => {
    try {
      await logout();
      console.log("User logged out successfully");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  let content = null;

  switch (activeLink) {
    case "dashboard":
      content = <Home />;
      break;
    case "assignments":
      content = <Assignments />;
      break;
    case "transactions":
      content = <Transactions />;
      break;
    case "notifications":
      content = <Notifications />;
      break;
    case "appoitnments":
      content = <Appoitments />;
      break;
    case "users":
      content = <Users />;
      break;
    case "quiz":
      content = <Quiz />;
      break;
    case "billing":
      content = <Billing />;
      break;
  }

  const fetchAssignments = async () => {
    try {
      const data = await fetchAllAssignments();
      setAssignments(data);
    } catch (error) {
      console.error("Error fetching assignments:", error);
    }
  };

  // Effect to fetch user data from local storage on component mount
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userJSON = localStorage.getItem("user");
        if (userJSON) {
          const userData = JSON.parse(userJSON);
          const currentUser = await getUserByUid(extractUid(userData));
          setUser(currentUser);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUser();
    fetchAssignments();
  }, [getUserByUid, extractUid]);
  return (
    <div>
      <div className={`flex h-screen ${isDarkMode ? "dark" : ""}`}>
        <div className="fixed w-full z-30 flex bg-sidebar p-2 items-center justify-center h-16 px-10">
          <div className="logo ml-20 font-semibold text-gray-100 transform ease-in-out duration-500 flex-none h-full flex items-center justify-center">
            EDUMATE
          </div>

          <div className="grow h-full flex items-center justify-center"></div>
          <div className="flex-none h-full text-center flex items-center justify-center">
            <div className="flex space-x-3 items-center px-3">
              <div className="flex-none flex justify-center">
                <div className="w-8 h-8 flex">
                  <img
                    src="../../..//logo.PNG"
                    alt="profile"
                    className="border p-1 rounded-full object-cover"
                  />
                </div>
              </div>

              <div className="hidden md:block font-semibold text-sm md:text-md text-gray-100">
                {user ? <div>{user.name}</div> : <div>Edumate</div>}
              </div>
            </div>
          </div>
        </div>
        <aside
          className={`w-60 ${
            isSidebarOpen ? "" : "-translate-x-48"
          } fixed transition transform ease-in-out duration-1000 z-50 flex h-screen bg-sidebar `}
        >
          {isSidebarOpen ? (
            <div
              className={`max-toolbar translate-x-5 scale-x-4 -right-0 transition transform ease-in duration-300 flex items-center justify-between border-4 ${
                isSidebarOpen ? "border-green-500" : "border-red-500"
              } dark:border-[#0F172A] bg-[#1E293B] absolute top-2 rounded-full h-12`}
            >
              <div className="flex pl-4 items-center space-x-2 ">
                <div
                  onClick={() => handleLinkClick("notifications")}
                  className="text-white mr-2 hover:text-blue-500 dark:hover:text-[#38BDF8]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={3}
                    stroke="currentColor"
                    className="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                    />
                  </svg>
                </div>
              </div>
              <div className="flex items-center space-x-3 group bg-[#719ea8]  pl-10 pr-2 py-1 rounded-full text-white">
                <div className="transform ease-in-out text-gray-300 duration-300 mr-12">
                  EDUMATE
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}

          <div
            onClick={toggleSidebar}
            className={`-right-6 transition transform ease-in-out duration-500 flex border-4 ${
              isSidebarOpen ? "border-green-500" : "border-red-500"
            } dark:border-[#0F172A] bg-[#1E293B] absolute top-2 rounded-full w-12 h-12 justify-center items-center`}
          >
            <img
              src="../../..//logo.PNG"
              className="p-2 hover:cursor-pointer"
            />
          </div>

          <div class="max-toolbar translate-x-24 scale-x-0 w-full -right-6  transition transform ease-in duration-300 flex items-center justify-between border-4 border-white dark:border-[#0F172A] bg-[#1E293B]  absolute top-2 rounded-full h-12">
            <div class="flex pl-4 items-center space-x-2 ">
              <div>
                <div
                  onclick="setDark('dark')"
                  class="moon text-white hover:text-blue-500 "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={3}
                    stroke="currentColor"
                    class="w-4 h-4"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
                    />
                  </svg>
                </div>
                <div
                  onclick="setDark('light')"
                  class="sun hidden text-white hover:text-blue-500 "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-4 h-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                    />
                  </svg>
                </div>
              </div>
              <div class="text-white hover:text-blue-500 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={3}
                  stroke="currentColor"
                  class="w-4 h-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                  />
                </svg>
              </div>
            </div>
            <div class="flex items-center space-x-3 group bg-gradient-to-r dark:from-cyan-500 dark:to-blue-500 from-indigo-500 via-purple-500 to-purple-500  pl-10 pr-2 py-1 rounded-full text-white  ">
              <div class="transform ease-in-out duration-300 mr-12">
                EDUMATE
              </div>
            </div>
          </div>

          {isSidebarOpen ? (
            <div class="max text-white mt-20 flex-col space-y-2 w-full h-[calc(100vh)]">
              <ul class="space-y-2 font-medium mt-2">
                <li>
                  <a
                    href="#"
                    onClick={() => handleLinkClick("dashboard")}
                    className={` flex items-center p-2 text-[#719fa9] hover:bg-gray-100 group-hover:text-gray-900 group${
                      activeLink === "dashboard"
                        ? "active bg-backgroundMain border-l-4 border-[#719fa9]"
                        : ""
                    }`}
                  >
                    <svg
                      class="w-5 h-5 text-[#719fa9] transition duration-75  "
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 22 21"
                    >
                      <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                      <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                    </svg>
                    <span class="ms-3 font-semibold">Dashboard</span>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() => handleLinkClick("appoitnments")}
                    className={` flex items-center p-2 text-[#719fa9] hover:bg-gray-100 group-hover:text-gray-900 group${
                      activeLink === "appoitnments"
                        ? "active bg-backgroundMain border-l-4 border-[#719fa9]"
                        : ""
                    }`}
                  >
                    <svg
                      class="w-5 h-5 text-[#719fa9] transition duration-75"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 18 18"
                    >
                      <path d="M6.143 0H1.857A1.857 1.857 0 0 0 0 1.857v4.286C0 7.169.831 8 1.857 8h4.286A1.857 1.857 0 0 0 8 6.143V1.857A1.857 1.857 0 0 0 6.143 0Zm10 0h-4.286A1.857 1.857 0 0 0 10 1.857v4.286C10 7.169 10.831 8 11.857 8h4.286A1.857 1.857 0 0 0 18 6.143V1.857A1.857 1.857 0 0 0 16.143 0Zm-10 10H1.857A1.857 1.857 0 0 0 0 11.857v4.286C0 17.169.831 18 1.857 18h4.286A1.857 1.857 0 0 0 8 16.143v-4.286A1.857 1.857 0 0 0 6.143 10Zm10 0h-4.286A1.857 1.857 0 0 0 10 11.857v4.286c0 1.026.831 1.857 1.857 1.857h4.286A1.857 1.857 0 0 0 18 16.143v-4.286A1.857 1.857 0 0 0 16.143 10Z" />
                    </svg>
                    <span class="flex-1 ms-3 font-semibold whitespace-nowrap">
                      Appoitnments
                    </span>
                    <span class="inline-flex items-center justify-center px-2 ms-3 text-sm font-medium text-gray-800 bg-gray-100 rounded-full dark:bg-gray-700 dark:text-gray-300">
                      Pro
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() => handleLinkClick("quiz")}
                    className={` flex items-center p-2 text-[#719fa9] hover:bg-gray-100 group-hover:text-gray-900 group${
                      activeLink === "quiz"
                        ? "active bg-backgroundMain border-l-4 border-[#719fa9]"
                        : ""
                    }`}
                  >
                    <svg
                      class="w-5 h-5 text-[#719fa9] transition duration-75"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M5 5V.13a2.96 2.96 0 0 0-1.293.749L.879 3.707A2.96 2.96 0 0 0 .13 5H5Z" />
                      <path d="M6.737 11.061a2.961 2.961 0 0 1 .81-1.515l6.117-6.116A4.839 4.839 0 0 1 16 2.141V2a1.97 1.97 0 0 0-1.933-2H7v5a2 2 0 0 1-2 2H0v11a1.969 1.969 0 0 0 1.933 2h12.134A1.97 1.97 0 0 0 16 18v-3.093l-1.546 1.546c-.413.413-.94.695-1.513.81l-3.4.679a2.947 2.947 0 0 1-1.85-.227 2.96 2.96 0 0 1-1.635-3.257l.681-3.397Z" />
                      <path d="M8.961 16a.93.93 0 0 0 .189-.019l3.4-.679a.961.961 0 0 0 .49-.263l6.118-6.117a2.884 2.884 0 0 0-4.079-4.078l-6.117 6.117a.96.96 0 0 0-.263.491l-.679 3.4A.961.961 0 0 0 8.961 16Zm7.477-9.8a.958.958 0 0 1 .68-.281.961.961 0 0 1 .682 1.644l-.315.315-1.36-1.36.313-.318Zm-5.911 5.911 4.236-4.236 1.359 1.359-4.236 4.237-1.7.339.341-1.699Z" />
                    </svg>

                    <span class="flex-1 ms-3 font-semibold whitespace-nowrap">
                      Edulearn
                    </span>
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    onClick={() => handleLinkClick("users")}
                    className={` flex items-center p-2 text-[#719fa9] hover:bg-gray-100 group-hover:text-gray-900 group${
                      activeLink === "users"
                        ? "active bg-backgroundMain border-l-4 border-[#719fa9]"
                        : ""
                    }`}
                  >
                    <svg
                      class="w-5 h-5 text-[#719fa9] transition duration-75"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 18"
                    >
                      <path d="M14 2a3.963 3.963 0 0 0-1.4.267 6.439 6.439 0 0 1-1.331 6.638A4 4 0 1 0 14 2Zm1 9h-1.264A6.957 6.957 0 0 1 15 15v2a2.97 2.97 0 0 1-.184 1H19a1 1 0 0 0 1-1v-1a5.006 5.006 0 0 0-5-5ZM6.5 9a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9ZM8 10H5a5.006 5.006 0 0 0-5 5v2a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1v-2a5.006 5.006 0 0 0-5-5Z" />
                    </svg>
                    <span class="flex-1 ms-3 font-semibold whitespace-nowrap">
                      Users
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() => handleLinkClick("billing")}
                    className={` flex items-center p-2 text-[#719fa9] hover:bg-gray-100 group-hover:text-gray-900 group${
                      activeLink === "billing"
                        ? "active bg-backgroundMain border-l-4 border-[#719fa9]"
                        : ""
                    }`}
                  >
                    <svg
                      class="w-5 h-5 text-[#719fa9] transition duration-75"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 18 20"
                    >
                      <path d="M17 5.923A1 1 0 0 0 16 5h-3V4a4 4 0 1 0-8 0v1H2a1 1 0 0 0-1 .923L.086 17.846A2 2 0 0 0 2.08 20h13.84a2 2 0 0 0 1.994-2.153L17 5.923ZM7 9a1 1 0 0 1-2 0V7h2v2Zm0-5a2 2 0 1 1 4 0v1H7V4Zm6 5a1 1 0 1 1-2 0V7h2v2Z" />
                    </svg>
                    <span class="flex-1 ms-3 font-semibold whitespace-nowrap">
                      Billing
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() => logoutUser()}
                    class="flex items-center p-2 text-[#719fa9] hover:bg-gray-100 group-hover:text-gray-900 group"
                  >
                    <svg
                      className="w-5 h-5 text-[#719fa9] transition duration-75"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 18 16"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                        d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"
                      />
                    </svg>
                    <span class="flex-1 ms-3 font-semibold whitespace-nowrap">
                      Sign In
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          ) : (
            <div className="mini mt-20 flex flex-col space-y-2 w-full h-[calc(100vh)]">
              <div
                onClick={() => handleLinkClick("dashboard")}
                className="hover:ml-4 justify-end pr-5 text-white hover:text-purple-500 dark:hover:text-blue-500 w-full bg-sidebar p-3 rounded-full transform ease-in-out duration-300 flex"
              >
                <svg
                  class="w-5 h-5 text-[#719fa9] transition duration-75  "
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 22 21"
                >
                  <path d="M16.975 11H10V4.025a1 1 0 0 0-1.066-.998 8.5 8.5 0 1 0 9.039 9.039.999.999 0 0 0-1-1.066h.002Z" />
                  <path d="M12.5 0c-.157 0-.311.01-.565.027A1 1 0 0 0 11 1.02V10h8.975a1 1 0 0 0 1-.935c.013-.188.028-.374.028-.565A8.51 8.51 0 0 0 12.5 0Z" />
                </svg>
              </div>
              <div
                onClick={() => handleLinkClick("billing")}
                className="hover:ml-4 justify-end pr-5 text-white hover:text-purple-500 dark:hover:text-blue-500 w-full bg-sidebar p-3 rounded-full transform ease-in-out duration-300 flex"
              >
                <svg
                  class="w-5 h-5 text-[#719fa9] transition duration-75"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 18 20"
                >
                  <path d="M17 5.923A1 1 0 0 0 16 5h-3V4a4 4 0 1 0-8 0v1H2a1 1 0 0 0-1 .923L.086 17.846A2 2 0 0 0 2.08 20h13.84a2 2 0 0 0 1.994-2.153L17 5.923ZM7 9a1 1 0 0 1-2 0V7h2v2Zm0-5a2 2 0 1 1 4 0v1H7V4Zm6 5a1 1 0 1 1-2 0V7h2v2Z" />
                </svg>
              </div>

              <div
                onClick={() => logoutUser()}
                className="hover:ml-4 justify-end pr-5 text-white hover:text-purple-500 dark:hover:text-blue-500 w-full bg-sidebar p-3 rounded-full transform ease-in-out duration-300 flex"
              >
                <svg
                  className="w-5 h-5 text-[#719fa9] transition duration-75"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 16"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    d="M1 8h11m0 0L8 4m4 4-4 4m4-11h3a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-3"
                  />
                </svg>
              </div>
            </div>
          )}
        </aside>
        <div
          className={`content flex-grow transform ease-in-out duration-1000 bg-backgroundMain ${
            isSidebarOpen ? "ml-60" : "ml-10"
          }`}
        >
          <div className="container pt-20">{content}</div>
        </div>
      </div>
    </div>
  );
}
