import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getStorage,
} from "firebase/storage";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  setDoc,
  doc,
  arrayUnion,
  addDoc,
} from "firebase/firestore";

export const uploadVideos = async (
  videos,
  courseName,
  authorName,
  estimatedTime,
  price,
  onProgress
) => {
  const storage = getStorage();
  const db = getFirestore();

  const videoUploadPromises = videos.map((video, index) => {
    const videoRef = ref(storage, `courses/${courseName}/videos/${video.name}`);
    const uploadTask = uploadBytesResumable(videoRef, video);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          onProgress(index, progress);
        },
        (error) => reject(error),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            const videoDetails = {
              name: video.name,

              url: downloadURL,
            };
            const courseRef = doc(db, "courses", courseName);

            // Use setDoc to add videoDetails to the course document
            setDoc(
              courseRef,
              {
                videos: arrayUnion(videoDetails),
                author: authorName,
                price: price,
                time: estimatedTime,
              },
              { merge: true }
            )
              .then(() => resolve(videoDetails))
              .catch((error) => reject(error));
          });
        }
      );
    });
  });

  return Promise.all(videoUploadPromises);
};

/**
  Fetch all courses from Firestore.
 */

export const getCourses = async () => {
  const db = getFirestore();
  try {
    // Reference to the "courses" collection
    const coursesRef = collection(db, "courses");

    // Get all documents from the "courses" collection
    const querySnapshot = await getDocs(coursesRef);

    // Initialize an array to hold the courses
    const courses = [];

    // Iterate through the documents and add them to the courses array
    querySnapshot.forEach((doc) => {
      courses.push({ id: doc.id, ...doc.data() });
    });
    console.log(courses);

    return courses;
  } catch (error) {
    console.error("Error getting courses: ", error);
    throw new Error("Could not fetch courses");
  }
};
