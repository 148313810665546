import React, { useState, useEffect } from "react";
import { Spinner, Button, Card } from "react-bootstrap";

export default function Questions() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      setLoading(false);
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "40vh" }}
      >
        <Spinner animation="border" className="text-textcolor" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  }
  return (
    <section className="md:container p-1">
      <div className="bg-backgroundMain text-white">
        <div className="text-textcolor">
          <div className="flex justify-between items-center mb-3 mt-3">
            <h2 className="text-textcolor p-1 text-xl font-bold">
              <i className="fas fa-list-ul mr-2"></i> Questions
            </h2>
          </div>
          <div className="bg-cardsButtons py-16">
            <div className="text-center">
              <p className="text-textcolor text-2xl font-bold">Ask questions</p>
              <p className="text-textcolor text-sm">
                you havent asked any questions!
              </p>
            </div>
          </div>
          {/* <div className="mt-3 bg-gray-100 p-3 mb-2 rounded-lg shadow-inner">
            <h5 className="text-textcolor font-semibold">
              <i className="fas fa-info-circle mr-2 text-blue-600"></i>
              Explanation:
            </h5>
            <Card.Text className="mt-2 text-blue-600 font-medium" />
          </div> */}
        </div>

        <div className="flex justify-between p-4 bg-sidebar">
          <Button variant="secondary">
            <i className="fa fa-chevron-left fa-sm mr-2"></i> Prev{" "}
          </Button>
          <Button variant="secondary">
            Next <i className="fa fa-chevron-right fa-sm ml-2"></i>{" "}
          </Button>
        </div>
      </div>
    </section>
  );
}
