import React, { createContext, useState, useEffect, useContext } from "react";
import {
  signInWithEmailAndPassword,
  sendEmailVerification,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../firebase/Firebase";
import {
  getFirestore,
  setDoc,
  doc,
  getDoc,
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      setUser(JSON.parse(loggedInUser));
    }
  }, []);

  const signup = async (formData) => {
    try {
      const { name, email, phoneNumber, password } = formData;

      // Create user with email and password
      const { user: newUser } = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Send email verification
      await sendEmailVerification(newUser);

      // Save user data in Firestore
      const db = getFirestore();
      await setDoc(doc(db, "students", newUser.uid), {
        uid: newUser.uid,
        name,
        email,
        phoneNumber,
        role: "student",
      });

      return newUser.uid;
    } catch (error) {
      throw error;
    }
  };

  const getUserByUid = async (uid) => {
    try {
      const db = getFirestore();
      const userDoc = await getDoc(doc(db, "students", uid));
      return userDoc.exists() ? userDoc.data() : null;
    } catch (error) {
      throw error;
    }
  };

  const getAllStudents = async () => {
    try {
      const db = getFirestore();
      const studentsCollection = collection(db, "students");
      const studentsSnapshot = await getDocs(studentsCollection);
      const studentsList = studentsSnapshot.docs.map((doc) => doc.data());
      return studentsList;
    } catch (error) {
      throw error;
    }
  };

  const updateUser = async (uid, updatedData) => {
    try {
      const db = getFirestore();
      const userRef = doc(db, "students", uid);
      await updateDoc(userRef, updatedData);
    } catch (error) {
      throw error;
    }
  };

  const deleteUser = async (uid) => {
    const db = getFirestore();
    try {
      await deleteDoc(doc(db, "students", uid));
    } catch (error) {
      throw error;
    }
  };

  const login = async (email, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      const currentUser = userCredential.user;

      // Save user in local storage
      localStorage.setItem("user", JSON.stringify(currentUser));
      setUser(currentUser);

      // Redirect based on user role
      const userData = await getUserByUid(currentUser.uid);
      const redirectUrl =
        userData && userData.role === "Admin" ? "/admin" : "/default";
      window.location.href = redirectUrl;
    } catch (error) {
      throw error;
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("user");
    window.location.href = "/login";
  };

  const extractUid = (userObject) => userObject?.uid || null;

  return (
    <UserContext.Provider
      value={{
        user,
        login,
        extractUid,
        getUserByUid,
        logout,
        signup,
        getAllStudents,
        updateUser,
        deleteUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};

export { UserProvider, useUser };
