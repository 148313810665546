import React from "react";
import { Link } from "react-router-dom";
import Hero from "../../components/common/hero/Hero";

export default function Events() {
  return (
    <>
      <Hero
        heading="Events"
        paragraph="Explore our vibrant calendar of academic events featuring workshops, seminars, and conferences across various disciplines. Join us online or in person to engage with leading experts and expand your knowledge."
        buttonText="Enquire"
        backgroundImage=""
      />

      {/* <div className="row row-cols-1 row-cols-md-3 g-4">
          <div className="col">
            <div className="card h-100 position-relative">
              <div className="bottom-right mb-2">17/08/2023</div>
              <div className="top-right">
                <div className="tags-list mb-2">
                  <span className="tags-list-town text-uppercase">
                    NEW YORK
                  </span>
                  <span className="badge bg-success text-uppercase font-weight-bold">
                    FREE
                  </span>
                </div>
              </div>
              <div className="image-container">
                <div className="bottom-left">
                  <div
                    className="thumbnail"
                    style={{ backgroundColor: "#ffa5009e" }}
                  >
                    <div className="thumbnail-date">
                      <span className="thumbnail-date-day">17</span>
                      <span className="thumbnail-date-month">ago</span>
                    </div>
                  </div>
                </div>
                <a href="#">
                  <img
                    src="https://images.pexels.com/photos/1230397/pexels-photo-1230397.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    className="card-img-top"
                    alt="Event"
                  />
                </a>
                <div className="image-overlay text-over text-center d-flex justify-content-center align-items-center text-uppercase">
                  <div>Cheers and Grooves in the Heart of New York!</div>
                </div>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card h-100 position-relative">
              <div className="bottom-right mb-2">20/09/2023</div>
              <div className="top-right">
                <div className="tags-list mb-2">
                  <span className="tags-list-town text-uppercase">ROME</span>
                  <span className="badge bg-success text-uppercase font-weight-bold">
                    FREE
                  </span>
                </div>
              </div>
              <div className="image-container">
                <div className="bottom-left">
                  <div
                    className="thumbnail"
                    style={{ backgroundColor: "#00800070" }}
                  >
                    <div className="thumbnail-date">
                      <span className="thumbnail-date-day">20</span>
                      <span className="thumbnail-date-month">sep</span>
                    </div>
                  </div>
                </div>
                <a href="#">
                  <img
                    src="https://images.pexels.com/photos/3171837/pexels-photo-3171837.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    className="card-img-top"
                    alt="Event"
                  />
                </a>
                <div className="image-overlay text-over text-center d-flex justify-content-center align-items-center text-uppercase">
                  <div>CSips and Sway: Where Rome Dances the Night Away!</div>
                </div>
              </div>
            </div>
          </div>

          <div className="col">
            <div className="card h-100 position-relative">
              <div className="bottom-right mb-2">31/12/2023</div>
              <div className="top-right">
                <div className="tags-list mb-2">
                  <span className="tags-list-town text-uppercase">PARIS</span>
                  <span className="badge bg-success text-uppercase font-weight-bold">
                    FREE
                  </span>
                </div>
              </div>
              <div className="image-container">
                <div className="bottom-left">
                  <div
                    className="thumbnail"
                    style={{ backgroundColor: "#ff000070" }}
                  >
                    <div className="thumbnail-date">
                      <span className="thumbnail-date-day">31</span>
                      <span className="thumbnail-date-month">dec</span>
                    </div>
                  </div>
                </div>
                <a href="#">
                  <img
                    src="https://images.pexels.com/photos/2526105/pexels-photo-2526105.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    className="card-img-top"
                    alt="Event"
                  />
                </a>
                <div className="image-overlay text-over text-center d-flex justify-content-center align-items-center text-uppercase">
                  <div>Countdown to Joy: New Year's Eve Bash in Paris!</div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

      {/* Placeholder for future content */}
      <div className="bg-gray-200">
        <div className="container py-10">
          <div className="py-16">
            <div className="text-center">
              <p className="text-textcolor text-2xl font-bold mb-2">
                Coming Soon
              </p>
              <p className="text-textcolor text-sm">
                Stay tuned for our pricelist!
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
